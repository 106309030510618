import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import { Hidden, ExpansionPanel, Toolbar, Typography, Checkbox, Button, Tooltip } from "@material-ui/core";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableFooter from "@material-ui/core/TableFooter";

import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./ResponsiveTable.css";

//draggable table
import DragDropContext from "react-dnd/lib/DragDropContext";
import HTML5Backend from "react-dnd-html5-backend";
import DraggableRow from "./DraggableRow";

//drag and drop
import DragDrop from "../dnd/DragDrop";

import { DragDropContext as DragDropCtx } from "react-beautiful-dnd";

import moment from "moment";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";


@inject("profileStore")
@observer
class ResponsiveTable extends Component {
    state = {
        rows: [],
        columns: [],
        page: 0,
        rowsPerPage: 5,
        orderBy: undefined,
        order: undefined,
        row : undefined
    };

    componentDidMount() {
        this.setState({
            ...this.state,
            rows: this.props.rows,
            columns: this.props.columns
        });
    }

    componentWillReceiveProps(nextProps) {
        var newRows = nextProps.rows.map(row => {
            if (this.state && this.state.rows) {
                var filteredRow = this.state.rows.filter(data => data.id === row.id);
                row.checked = ((filteredRow[0] ? filteredRow[0].checked : false) || row.checked) && !row.disableCheckbox;
            }

            return row;
        });

        this.setState({
            ...this.state,
            rows: newRows,
            columns: nextProps.columns
        });
    }

    reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    //table dnd handle
    moveRow = (dragIndex, hoverIndex) => {
        var result = this.reorder(this.state.rows, dragIndex, hoverIndex);

        this.setState(
            {
                ...this.state,
                rows: result
            },
            () => {
                if (this.props.postDragEvent) {
                    this.props.postDragEvent(result);
                }
            }
        );
    };

    // card dnd handler
    onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const items = this.reorder(this.state.rows, result.source.index, result.destination.index);

        this.setState(
            {
                ...this.state,
                rows: items
            },
            () => {
                if (this.props.postDragEvent) {
                    this.props.postDragEvent(items);
                }
            }
        );
    };

    handleCheckboxChange = (e, checked, row) => {
        this.state.rows.forEach(element => {
            if (element === row) {
                element.checked = checked;
            }
            if(element !==row && this.props.single){
                element.checked = false;
            }
        });

        this.setState(
            {
                ...this.state,
                rows: this.state.rows
            },
            () => {
                if (this.props.getCheckedRows && this.props.selectable) {
                    this.props.getCheckedRows(this.getCheckedRows());
                }
            }
        );
    };

    handleSelectAll = (e, checked) => {
        var newRowsData;
        newRowsData = this.state.rows.map(row => {
            row.checked = checked;
            return row;
        });

        this.setState(
            {
                ...this.state,
                rows: newRowsData
            },
            () => {
                if (this.props.getCheckedRows && this.props.selectable) {
                    this.props.getCheckedRows(this.getCheckedRows());
                }
            }
        );
    };

    handleSortButtonClicked = column => {
        let order = "desc";

        if (this.state.order === "asc") {
            order = "desc";
        } else if (this.state.order === "desc") {
            order = undefined;
        } else if (!this.state.order) {
            order = "asc";
        }

        this.setState({
            ...this.state,
            order: order,
            orderBy: column
        });
    };

    handleSort = (order, orderBy) => {
        const { columns } = this.state;

        let orderedColumn = columns.filter(x => x && x.key === orderBy)[0];
        let isDate,
            isNumber = false,
            addComp,
            isString;

        if (orderedColumn) {
            isDate = orderedColumn.isDate;
            isNumber = orderedColumn.isNumber;
            addComp = orderedColumn.addComp;
            isString = orderedColumn.isString;
        }

        return (a, b) => {
            var tempA = a[orderBy];
            var tempB = b[orderBy];

            if (addComp) {
                tempA = tempA.value;
                tempB = tempB.value
            }

            if (isDate) {
                console.log(tempA, tempB)
                // tempA = moment(tempA);
                // tempB = moment(tempB);
            }
            if (isNumber) {
                tempA = Number(tempA.replace(/[^\d.-]/g, ''));
                tempB = Number(tempB.replace(/[^\d.-]/g, ''));
            }

            if (isString) {
                tempA = tempA.replace( /\s/g, '').toLowerCase()
                tempB = tempB.replace( /\s/g, '').toLowerCase()
            }

            if (order === "desc") {
                return tempB < tempA ? -1 : 1;
            } else if (order === "asc") {
                return tempA < tempB ? -1 : 1;
            }
        };
    };

    //render table
    renderTableHeader = () => {
        const { order, orderBy } = this.state;
        return (
            <TableRow>
                {this.props.draggable && <TableCell>&nbsp;</TableCell>}
                {this.props.selectable && (
                    <TableCell className="checkbox-column">
                        {!this.props.hideSelectAll ? (
                            <Checkbox
                                indeterminate={this.getCheckedRows().length > 0 && this.getCheckedRows().length < this.state.rows.length}
                                checked={this.getCheckedRows().length === this.state.rows.length}
                                onChange={this.handleSelectAll}
                                disabled={this.props.agentAct ? false : this.state.rows.filter(x => x.disableCheckbox).length > 0}
                            />
                        ) : (
                            ""
                        )}
                    </TableCell>
                )}
                {this.state.columns.map((column, i) => {
                    return column.sortable && this.props.sortable ? (
                        <TableCell
                            key={i}
                            numeric={column.isNumber}                            
                            style={{ ...column.style, width: column.width, textAlign: column.align }}
                            sortDirection={orderBy === column.key ? order : false}
                        >
                            <Tooltip title="Sort" placement={!column.isNumber ? "bottom-end" : "bottom-start"} enterDelay={30}>
                                <TableSortLabel
                                    active={orderBy === column.key && order !== undefined && !this.props.hideSortableArrow}
                                    direction={order}
                                    onClick={() => this.handleSortButtonClicked(column.key)}
                                >
                                    {column.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ) : (
                        <TableCell align={column.align} numeric={column.isNumber} key={i} style={{ ...column.style, width: column.width, textAlign: column.align }}>
                            {column.label}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    };

    renderFooterRow = () => {
        const { footerRow } = this.props;
        return (
            <Fragment>
                {this.props.selectable && <TableCell className="checkbox-column">&nbsp;</TableCell>}
                {this.state.columns.map((column, i) => {
                    return (
                        <TableCell align={column.align} key={i} numeric={column.isNumber} style={footerRow.style}>
                            {" "}
                            {this.formatData(footerRow[column.key], column.type, column.prefix)}{" "}
                        </TableCell>
                    );
                })}
            </Fragment>
        );
    };

    renderTableData = (row, index) => {
        let renderRow = () => (
            <Fragment>
                {this.props.selectable &&
                    row.checked && (
                        <TableCell className="checkbox-column" >
                        {/* style={{backgroundColor: this.props.highLight && row.id && this.props.highLight == row.id ? "#FF0C9C" : ""}}> */}
                            <Checkbox
                                checked={true}
                                onChange={(e, value) => this.handleCheckboxChange(e, value, row)}
                                disabled={row.disableCheckbox}
                            />
                        </TableCell>
                    )}
                {this.props.selectable &&
                    !row.checked && (
                        <TableCell className="checkbox-column" >
                        {/* style={{backgroundColor: this.props.highLight && row.id && this.props.highLight == row.id ? "#FF0C9C" : ""}} > */}
                            <Checkbox
                                checked={false}
                                onChange={(e, value) => this.handleCheckboxChange(e, value, row)}
                                disabled={row.disableCheckbox}
                            />
                        </TableCell>
                    )}
                {this.state.columns.map((column, i) => {
                    return (
                        <TableCell numeric={column.isNumber} key={i} 
                        style={{...column.style, textAlign: column.align}}>
                            {" "}
                            {column.addComp ?
                                this.formatData(row[column.key].newFormat, column.type, column.prefix) :
                                this.formatData(row[column.key], column.type, column.prefix)
                            }
                            {" "}
                        </TableCell>
                    );
                })}
            </Fragment>
        );

        return this.props.draggable ? (
            <DraggableRow key={index} moveRow={this.moveRow} index={index} content={renderRow()} rowStyle={row.style} />
        ) : (
            
            <TableRow hover key={index} style={this.props.highLight && row.id && this.props.highLight == row.id ? row.styleHighlight : row.style } onClick={(event) => this.onClickRow(row)}>
                {renderRow()}
            </TableRow>
        );
    };

    formatData(data, dataType, prefix) {
        if (!dataType) {
            return data;
        }

        switch (dataType) {
            case "currency":
                if (!prefix) {
                    const { currentProfile } = this.props.profileStore;
                    prefix = ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$");
                }
                return `${prefix}${parseFloat(data).toLocaleString()}`;
            default:
                return data;
        }
    }

    getPrimaryColumn = () => {
        let primaryColumns = this.state.columns.find(column => column.primary);

        primaryColumns = primaryColumns ? primaryColumns : this.state.columns[0];

        return primaryColumns;
    };

    getCheckedRows = () => {
        return this.state.rows.filter(row => {
            return row.checked;
        });
    };

    renderRowCard = (row, index) => {
        let filteredColumns = this.state.columns.filter(col => col.hide === undefined || col.hide === false);
        return (
            <ExpansionPanel key={index} style={row.style}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    {this.props.selectable ? (
                        <Checkbox checked={row.checked} onChange={(e, checked) => this.handleCheckboxChange(e, checked, row)} value={row} />
                    ) : (
                        ""
                    )}
                    {this.getPrimaryColumn() && this.getPrimaryColumn().key && row[this.getPrimaryColumn().key] && row[this.getPrimaryColumn().key].newFormat && row[this.getPrimaryColumn().key].newFormat ? row[this.getPrimaryColumn().key].newFormat : row[this.getPrimaryColumn()?.key]}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Table>
                        <TableBody>
                            {filteredColumns.map((column, i) => {
                                return (
                                    <TableRow key={i}>
                                        <TableCell style={{ width: "30%", paddingRight: "8px" }}>{column.label}</TableCell>
                                        <TableCell>{row[column.key]?.newFormat ? row[column.key]?.newFormat : row[column.key]}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    };

    renderExpansionData = () => {
        if (this.state.rows && this.state.rows.length > 0) return this.state.rows.map((row, i) => this.renderRowCard(row, i));
        else {
            return <Typography className="text-center"> No data found. </Typography>;
        }
    };

    renderToolbar = () => (
        <Toolbar
            classes={{
                root: this.getCheckedRows().length === 0 ? "table-toolbar" : "table-toolbar-selected"
            }}
        >
            <div>
                {this.getCheckedRows().length > 0 ? (
                    <Typography variant="subtitle1" style={{ color: "#FFF" }}>
                        {this.getCheckedRows().length} selected
                    </Typography>
                ) : (
                    <Typography variant="subtitle1">{this.props.title}</Typography>
                )}
            </div>
            <div className="pull-right actions">
                {this.props.customActions ? this.props.customActions : ""}
                {this.getCheckedRows().length === 0 && this.props.handleAdd ? (
                    <Button onClick={() => this.props.handleAdd()}>
                        <AddIcon style={{color:"white"}}/>
                        <span style={{ paddingTop: 3, color:"white" }}>{this.props.addLabel ? this.props.addLabel : "Add"}</span>
                    </Button>
                ) : (
                    ""
                )}
                {this.getCheckedRows().length > 0 && this.props.handleDelete ? (
                    <Button onClick={() => this.props.handleDelete(this.getCheckedRows())}>
                        <DeleteIcon style={{ color: "#FFF" }} />
                        <span style={{ paddingTop: 3, color: "#FFF" }}>{this.props.deleteLabel ? this.props.deleteLabel : "Delete"}</span>
                    </Button>
                ) : (
                    ""
                )}
            </div>
        </Toolbar>
    );

    //event handler
    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    getSelectedRow = () => {
        return this.state.row
    }

    onClickRow = (rowData) =>{
        this.setState(
            {
                ...this.state,
                row: rowData
            },
            () => {
                if (this.props.getSelectedRow) {
                    this.props.getSelectedRow(this.getSelectedRow());
                }
            }
        );
    }

    render() {
        const { page, rowsPerPage, rows, columns, order, orderBy } = this.state;
        var renderedRows = rows.slice();
        if (this.props.sortable && order) {
            renderedRows = renderedRows.sort(this.handleSort(order, orderBy));
        }

        return (
            <Fragment>
                {this.props.showToolbar ? (this.props.renderToolbar ? this.props.renderToolbar : this.renderToolbar()) : ""}
                <Hidden smDown>
                    <Paper style={{ width: "100%", overflowX: "auto" }}>
                        <Table className="table-md">
                            <TableHead style={this.props.headerStyle} className={this.props.headerClassName} classes={this.props.headerClasses}>
                                {this.renderTableHeader()}
                            </TableHead>
                            <TableBody>
                                {this.props.pagination
                                    ? renderedRows
                                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                          .map((row, index) => this.renderTableData(row, index))
                                    : renderedRows.map((row, index) => this.renderTableData(row, index))}
                                {this.props.footerRow ? (
                                    <TableRow className={this.props.footerRowClassName} style={this.props.footerRowStyle}>
                                        {this.renderFooterRow()}
                                    </TableRow>
                                ) :  this.props.addSpace ? <TableRow className={this.props.footerRowClassName} style={{height:100}}> 
                            </TableRow> : null} 
                            </TableBody>
                            {this.props.pagination && (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={this.props.selectable ? columns.length + 1 : columns.length}
                                            rowsPerPage={rowsPerPage}
                                            SelectProps={{ className: "transparent-select" }}
                                            page={page}
                                            count={rows.length}
                                            backIconButtonProps={{
                                                "aria-label": "Previous Page"
                                            }}
                                            nextIconButtonProps={{
                                                "aria-label": "Next Page"
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </TableRow>
                                </TableFooter>
                            )}
                        </Table>
                    </Paper>
                </Hidden>
                <Hidden mdUp>
                    {this.props.draggable ? (
                        <DragDropCtx onDragEnd={this.onDragEnd}>
                            <DragDrop
                                items={rows}
                                droppableId="responsiveTable"
                                type="responsiveTable"
                                renderContent={this.renderRowCard}
                                horizontal={false}
                            />
                        </DragDropCtx>
                    ) : (
                        this.renderExpansionData()
                    )}
                </Hidden>
            </Fragment>
        );
    }
}

export default DragDropContext(HTML5Backend)(ResponsiveTable);
