import React, { Component, Fragment } from "react";

import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
// import NumberFormat from "react-number-format";
// import moment from "moment";
import { BookingStatusType, ProfileType, BookingType, LineupStatusType, RosterDividendType } from "../../types/enum";
import "./BookingDetails.css";
import { Grid, Typography, Button, Menu, MenuItem, Snackbar,IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ResponsiveTable from "../table/ResponsiveTable";
import BookingChangeActModal from "./BookingModal/BookingChangeActModal";
import BookingStatusBadge from "./BookingStatusBadge";
import Loading from "../loadingSpinner/Loading";
import moment from "moment";
import ConfirmationModal from "../modal/ConfirmationModal";
import currencyHelper from '../../helper/currency';
import Profile from "../../Profile";

const BOOKING_STATUS = [
    "New",
    "Declined",
    "Negotiate",
    "Accepted",
    "LineupSet",
    "SetlistSet",
    "Ready",
    "Completed",
    "Paid",
    "Cancelled",
    "Resent",
    "Draft",
    "Incomplete",
    "Cancelled By Act",
    "Cancelled By Act",
    "SentToAct",
    "PlayerNotified",
    "PlayerDeclined",
    "AllPlayerConfirmed",
    "ClientNotified",
    "PlayerInvitedAndClientNotified",
    "PlayerConfirmedAndClientNotified"
];

@inject("profileStore", "bookingRequestStore", "templateStore", "reportStore")
@withRouter
@observer
class   BookingRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelBookingModalState: false,
            declineBookingModalState: false,
            columns: [],
            changeActModalState: false,
            booking: undefined,
            actionAnchor: [],
            isLoading: false,
            duplicateModalState: true,
            duplicateModalState: null,
            openDuplicateSnackbar: false,
            duplicateBooking: undefined,
            deleteModalState: false,
            openDeleteSnackbar: false,
            deleteBooking: undefined,
            page: 1
        };

        this.row = [];
    }
    
    setActionAnchor = (element, bookingId) => {
        var tempAnchor = [];
        tempAnchor[bookingId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };

    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };

    componentDidMount() {
        
        if (this.props.profileType === "agent") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "10%", sortable: true, isDate:true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "10%" },
                    { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' }},
                    { key: "agentFee", label: "My Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "15%" }
                ]
            });
        } else if (this.props.profileType === "musician") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Venue/Client Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "myFee", label: "My Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "10%" }
                ]
            });
        } else if (this.props.profileType === "staff") {
            this.setState({
                ...this.state,
                columns: [
                    { key: "date", label: "Date", width: "15%", sortable: true, isDate: true, style: {whiteSpace: 'nowrap'}, addComp: true },
                    { key: "status", label: "Status", width: "15%" },
                    { key: "actName", label: "Act Name", width: "15%" },
                    { key: "venue", label: "Venue", width: "15%" },
                    { key: "gigFee", label: "Gig Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } }, 
                    { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    { key: "actFee", label: "Act Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
                    // { key: "acknowledge", label: "", width: "15%" },
                    { key: "bookingCreator", label: "Booking Creator", width: "10%" },
                    { key: "action", label: "", width: "15%" }
                ]
            });
        }
    }

    loadGigsRequests = (page) => {
        const {
            currentProfile
        } = this.props.profileStore;
        Promise.all([
            this.props.bookingRequestStore.loadGigsRequests(currentProfile?.id,page),
        ]).then(() => {
            this.setState({
                ...this.state,
                isLoading: false
            });
            this.props.bookingRequestStore.hideLoadingState();
            this.props.bookingRequestStore.setDraftPage(page)
        });
    }
    handleShowMore = e => {
        var page = this.props.bookingRequestStore.draftPage + 1;
        this.loadGigsRequests(page)
    };
    handleAcceptBooking = e => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.saveStatus(currentProfile?.id, BookingStatusType.Accepted.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking accepted!");
            this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
        });
    };

    handleHoldBooking = e => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.saveStatus(currentProfile?.id, BookingStatusType.Hold.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking hold!");
            this.props.templateStore.setEditMusicianBookingActiveStep(1);
        });
    };

    handleDeclineBooking = e => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.saveStatus(currentProfile?.id, BookingStatusType.Declined.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking declined!");
            this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
        });
    };

    handleOpenCancelModal = () => this.setState({ cancelBookingModalState: true });
    handleCloseCancelModal = () => this.setState({ cancelBookingModalState: false });

    handleOpenDeclineModal = () => this.setState({ declineBookingModalState: true });
    handleCloseDeclineModal = () => this.setState({ declineBookingModalState: false });
    
    handleSendReminder = booking => {
        this.props.bookingRequestStore.handleSendReminder(booking.id).then(() => {
            this.unsetActionAchnor()
            this.props.templateStore.openSnackbar("Booking request reminder sent");
        })
        
    }

    handleSendReminderRequest = booking => {
        this.props.bookingRequestStore.handleSendReminderRequest(booking.id).then(() => {
            this.unsetActionAchnor()
            this.props.templateStore.openSnackbar("Booking request reminder sent");
        })
        
    }
    
    handleOpenChangeActModal = booking => this.setState({ changeActModalState: true, booking: booking, actionAnchor: [] });
    handleCloseChangeActModal = () => {
        const { currentProfile } = this.props.profileStore;
        this.setState({
            ...this.state,
            isLoading: true
        });
        this.props.bookingRequestStore.resetRequestGigs()
        this.props.bookingRequestStore.loadGigsRequests(currentProfile?.id,1).then(() => {
            this.setState({
                ...this.state,
                isLoading: false,
                changeActModalState: false
            });
        });
    };

    handleCloseChangeActModalWithoutAction = () => {
        const { currentProfile } = this.props.profileStore;
        this.setState({
            ...this.state,
            isLoading: false,
            changeActModalState: false
        });
    };

    handleOpenDuplicateBookingModal = booking => this.setState({ duplicateModalState: true, duplicateBooking: booking, actionAnchor: [] });
    handleCloseDuplicateBookingModal = () => {
        this.setState({
            ...this.state,
            duplicateModalState: false,
            duplicateBooking: null
        });
    };
    handleDupicateRequest = () => {
        const { duplicateBooking } = this.state;
        const {currentProfile} = this.props.profileStore
        this.props.bookingRequestStore.duplicateBooking(duplicateBooking.id,currentProfile.id ).then(response => {
            this.handleCloseDuplicateBookingModal();
           
            this.setState({ openDuplicateSnackbar: true});
            setTimeout(
                function () {
                    this.props.history.replace(`/booking/${response.id}/edit`);
                }.bind(this),
                2000
            );
            
        });
    };

    handleSnackbarEditClose = (event, reason) => {
        this.setState({ openDuplicateSnackbar: false });
    };

    
    handleCancelBooking = e => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.saveStatus(currentProfile?.id, BookingStatusType.Cancelled.ordinal).then(() => {
            this.props.templateStore.openSnackbar("Booking cancelled!");
            this.props.templateStore.setEditMusicianBookingActiveStep(2); // skip negotiate
        });
    };

    getBookingStatus = booking => {
        const { currentProfile } = this.props.profileStore;
        const isAgent = currentProfile && currentProfile?.type === ProfileType.Agent.ordinal;
        const isStaff = currentProfile && currentProfile?.type === ProfileType.Staff.ordinal;
        const isStaffOrAgent = isStaff || isAgent;
        const isBookingCreator = booking.createdById === currentProfile?.id;

        if (booking.status === BookingStatusType.New.ordinal && booking.saveAsDraft) {
            return "Draft";
        }
        if (booking.status === BookingStatusType.New.ordinal && booking.notifyAct && currentProfile?.type === ProfileType.Agent.ordinal) {
            if (!isBookingCreator && booking.isAdmin) {
                return "New"
            } else {
                return "SentToAct";
            }
        }
        if (booking.status === BookingStatusType.New.ordinal && !booking.saveAsDraft && isStaffOrAgent) {
            return "Sent";
        }

        return BOOKING_STATUS[booking.status];
    };

    handleOpenDeleteBookingModal = booking => this.setState({ deleteModalState: true, deleteBooking: booking, actionAnchor: [] });
    handleCloseDeleteBookingModal = () => {
        this.setState({
            ...this.state,
            deleteModalState: false,
            deleteBooking: null
        });
    };
    handleDeleteRequest = () => {
        const { deleteBooking } = this.state;
        const {currentProfile} = this.props.profileStore
        this.props.bookingRequestStore.deleteBooking(deleteBooking.id, currentProfile.id).then(response => {
            this.handleCloseDeleteBookingModal();
           
            this.setState({ openDeleteSnackbar: true});
            this.props.bookingRequestStore.filterDeleteRequest(deleteBooking.id)
        });
    };

    handleSnackbarDeleteClose = (event, reason) => {
        this.setState({ openDeleteSnackbar: false });
    };

    renderFooterRow = () => {
        let { requests } = this.props.bookingRequestStore;
        const { profileType } = this.props;
        const { venueId } = this.props.reportStore;
        const currentProfile = this.props.profileStore.getCurrentProfile();
        
        if (venueId) {
            requests = requests.filter(x => x.venueId === venueId);            
        }
        var totalFee = 0;
        var totalActFee = 0;
        var totalAgentFee = 0;
        var totalMyFee = 0
        var filterRequest = requests.filter(x => !x.saveAsDraft && (x.notifyAct || x.notifyAgent || x.notifyClient) && x.status !== BookingStatusType.Draft.ordinal);
                
        filterRequest.forEach(booking => {
            let linuep = booking?.lineups?.find(x => x.profileId === currentProfile?.id);
            var musicianFee = parseFloat(booking.gigFee) - totalFee
            const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

            let adminFeeRoster = 0
            let totalRosterFeeAdmin = 0
            booking?.act?.actRoster?.forEach(actRoster => {
                let feeCount = 0
                if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                    feeCount = actRoster.dividendAmount /100 * booking.actFee;
                }else {
                    feeCount = actRoster.dividendAmount;
                }
                totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
            })
            adminFeeRoster = booking.actFee - totalRosterFeeAdmin
            if(currentProfile?.type === ProfileType.Musician.ordinal && !booking?.lineups?.length){
                let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
               
                if(actRoster?.length){
                    if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                        musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                    }else {
                        musicianFee = actRoster[0].dividendAmount;
                    }
                    if(this.props.profileType === 'musician'){
                        if(booking?.act?.actAdminId === currentProfile?.id){
                            musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                        }
                    }
                }else{
                    musicianFee = booking.actFee
                    booking?.act?.actRoster?.forEach(item => {
                        var rosterFee = 0
                        if(item.dividendType === RosterDividendType.Percentage.ordinal){
                            rosterFee = item.dividendAmount /100 * booking.actFee;
                        }else {
                            rosterFee = item.dividendAmount;
                        }
                        musicianFee = musicianFee - rosterFee
                    });
                }
            }
             
            var totalRosterFee = 0

            booking?.lineups?.forEach(lineUp => {
                totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
            })
            var adminFee = booking.actFee - totalRosterFee
            var calculateFee = linuep?.fee ?  linuep?.fee : 0
            if(this.props.profileType === 'musician'){
                if(booking?.act?.actAdminId === currentProfile?.id){
                    calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                }
            }
            
            let myFee = linuep?.fee ? calculateFee: isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;
          
            let actOwner = booking.act?.userId === currentProfile?.userId;
            let agentActOwner = currentProfile?.type === ProfileType.Agent.ordinal && booking.createdById !== currentProfile?.id && booking.act.actAdminId === currentProfile?.id;
            let totalLineupFee = 0

            totalFee += (booking.viewOnly) ? 0 : (profileType === "agent" || profileType === "staff") && booking.isApproved ? Number(booking.gigFee) + Number(booking.extraGigFee) : Number(booking.gigFee);
            // totalActFee += this.props.profileType === "musician" && !actOwner ? 0 : Number(booking.actFee);
            totalActFee += agentActOwner ? 0 : this.props.profileType === "musician" && !actOwner ? 0 : Number(booking.actFee);
            totalMyFee += Number(myFee);
            booking?.lineups?.forEach(l => {
                if (l.status !== LineupStatusType.Removed.ordinal) {
                    totalLineupFee += parseFloat(l.fee)
                }                                    
            }); 
            totalAgentFee += agentActOwner ? parseFloat(booking.actFee) - parseFloat(totalLineupFee) :  Number(booking.agentCommissionAmount);
        });
        
        return {
            gigFee: ( (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalFee, 2)}`,
            actFee: ( (currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalActFee, 2)}`,
            agentCommissionAmount: ((currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
            agentFee: ((currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalAgentFee, 2)}`, 
            myFee: ((currentProfile && currentProfile?.type === ProfileType.Staff.ordinal && !currentProfile?.allowInvoices))
            ? <i class="far fa-eye-slash"></i>  
            : `${currentProfile?.currencySymbol + currencyHelper.formatMoney(totalMyFee, 2)}`,
            style: {
                textAlign: 'left'
            }
        }
    }

    render() {
        let { requests, newRequests } = this.props.bookingRequestStore;
        const { currentProfile } = this.props.profileStore;
        const { profileType } = this.props;
        const { venueId } = this.props.reportStore;
        if (venueId) {
            newRequests = newRequests.filter(x => x.venueId === venueId);
            this.props.bookingRequestStore.setBookingsCount("requests", newRequests);
        }
        return (
            <Fragment>
                <Grid container spacing={8}>
                    <Grid item xs={12} lg={12}>
                        <div>
                            <Typography variant="headline" component="h3" gutterBottom>
                                Booking Requests
                            </Typography>
                            <Typography gutterBottom className="side-helper-text">
                                {profileType === "musician"
                                    ? "These are the incoming booking requests for the acts you are managing. You need to take action on these requests"
                                    : "These are the booking requests that you have sent or one of your agents have sent."}
                            </Typography>
                            <Typography>
                                <b>Tip:</b>
                                {profileType === "musician"
                                    ? " Click 'View Details' to accept the gig, assign players and create set lists."
                                    : " Click 'View Details' to see booking details."}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={12} className="booking-table-wrapper" style={{ position: "relative", minHeight: "200px" }}>
                        <Loading showed={this.state.isLoading} />
                        <ResponsiveTable
                            sortable={true}
                            columns={this.state.columns}
                            rows={newRequests.map(booking => {
                                var row = {};
                                const bookingRequestUrl = booking.bookingRequestId ? `/${booking.bookingRequestId}` : "";
                                var formattedDate = moment(booking.dateStart).format("YYYY-MM-DD")
                                
                                var status = this.getBookingStatus(booking);
                                let time = booking.timeStart.length < 8 ? `0${booking.timeStart}` : booking.timeStart;
                                let endTime = booking.timeEnd.length < 8 ? `0${booking.timeEnd}` : booking.timeEnd;
                                var finalDate= moment(formattedDate + ' ' + time)
                                row.date = {newFormat: 
                                    (
                                        <div>
                                            <span>{`${booking.dateStart.format("ddd, DD MMM YYYY")}`}</span>
                                            <br />
                                            <small>{`${moment(time).format('hh.mm A')} - ${moment(endTime).format('hh.mm A')}`}</small>
                                        </div>
                                    ), value: finalDate};
                                row.venue = (
                                    <div>
                                        <span>{booking.venue ? booking.venue.profileName : booking.venueName}</span>
                                        <br />
                                        <small>{booking.venueAddress}</small>
                                    </div>
                                );
                                // row.address = booking.venueAddress;
                                // row.agentName = booking.agentName;
                                // row.time = booking.timeStart.substring(0,5);
                                var deleteBookingAvailable = (booking.status === BookingStatusType.SentToAct.ordinal || status === "SentToAct") || (booking.status === BookingStatusType.Hold.ordinal)
                                let agentActOwner = currentProfile?.type === ProfileType.Agent.ordinal && booking.createdById !== currentProfile?.id && booking.act.actAdminId === currentProfile?.id;
                                row.gigFee = (booking.viewOnly) || (currentProfile && currentProfile.type === ProfileType.Staff.ordinal && !currentProfile.allowInvoices)
                                    ? <i class="far fa-eye-slash"></i>
                                    : `${currentProfile?.currencySymbol + ((profileType === "agent" || profileType === "staff") && booking.isApproved
                                        ? currencyHelper.formatMoney(booking.gigFee + booking.extraGigFee, 2) 
                                        : agentActOwner ? currencyHelper.formatMoney(booking.actFee, 2)
                                        : currencyHelper.formatMoney(booking.gigFee, 2))}`;
                                row.agentFee = currentProfile?.currencySymbol + currencyHelper.formatMoney(agentActOwner ? 0 : booking.agentCommissionAmount, 2);
                                // row.actName = booking.actName;

                                row.agentCommissionAmount = `${currentProfile?.currencySymbol}${currencyHelper.formatMoney(booking.agentCommissionAmount, 2)}`
                                row.actName =
                                    booking.status === BookingStatusType.Resent.ordinal &&
                                    (profileType === "agent" || profileType === "staff" || profileType === "venue") ? (
                                        <div>
                                            {booking.bookingRequests.map(bookingRequest => (
                                                <div>
                                                    <Link target={"_blank"} to={`/profile/${bookingRequest?.act?.id}`}>{bookingRequest.act ? bookingRequest?.act?.actName : ""}</Link>
                                                    <br />
                                                    <small>Contact: {bookingRequest?.act?.contactName}</small><br />
                                                    <small>Phone: {booking?.act?.contactPhone ? booking?.act?.contactPhone  : "-"}</small>
                                                    {/* <hr style={{ marginTop: "8px !important", marginBottom: "8px !important" }} /> */}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>
                                            <Link target={"_blank"} to={`/profile/${booking?.act?.id}`}>{booking.act ? booking?.act?.actName : ""}</Link>
                                            <br />
                                            {booking.act && <small>Contact: {booking?.act?.contactName}</small>}<br />
                                            {booking.act && <small>Phone: {booking?.act?.contactPhone ? booking?.act?.contactPhone  : "-"}</small>}
                                        </div>
                                    );

                                    let totalFee = 0;
                                    booking.lineups?.forEach(l => {
                                        if (l.status !== LineupStatusType.Removed.ordinal) {
                                            totalFee += parseFloat(l.fee)
                                        }                                    
                                    });  
                                    let linuep = booking.lineups && booking?.lineups?.find(x => x.profileId === currentProfile?.id);
                                    var musicianFee = parseFloat(booking.gigFee) - totalFee

                                    let adminFeeRoster = 0
                                    let totalRosterFeeAdmin = 0
                                    booking?.act?.actRoster?.forEach(actRoster => {
                                        let feeCount = 0
                                        if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                                            feeCount = actRoster.dividendAmount /100 * booking.actFee;
                                        }else {
                                            feeCount = actRoster.dividendAmount;
                                        }
                                        totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
                                    })
                                    adminFeeRoster = booking.actFee - totalRosterFeeAdmin
                                    if(currentProfile?.type === ProfileType.Musician.ordinal && !booking.lineups?.length){
                                        let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
                                        if(actRoster?.length){
                                            if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                                                musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                                            }else {
                                                musicianFee = actRoster[0].dividendAmount;
                                            }
                                            if(this.props.profileType === 'musician'){
                                                if(booking?.act?.actAdminId === currentProfile?.id){
                                                    musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                                                }
                                            }
                                        }else{
                                            musicianFee = booking.actFee
                                            booking?.act?.actRoster?.forEach(item => {
                                                var rosterFee = 0
                                                if(item.dividendType === RosterDividendType.Percentage.ordinal){
                                                    rosterFee = item.dividendAmount /100 * booking.actFee;
                                                }else {
                                                    rosterFee = item.dividendAmount;
                                                }
                                                musicianFee = musicianFee - rosterFee
                                            });
                                        }
                                    }

                                    const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

                                var totalRosterFee = 0

                                booking.lineups?.forEach(lineUp => {
                                    totalRosterFee = totalRosterFee + lineUp.fee
                                })
                                var adminFee = booking.actFee - totalRosterFee
                                var calculateFee = linuep?.fee ?  linuep?.fee : 0
                                if(this.props.profileType === 'musician'){
                                    if(booking?.act?.actAdminId === currentProfile?.id){
                                        calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                                    }
                                }

                                    let myFee = linuep?.fee ? calculateFee : isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;
    
                                    row.myFee = this.props.profileType === 'musician' && currentProfile?.currencySymbol + currencyHelper.formatMoney(myFee, 2);
    
                                row.bookingCreator = booking?.createdBy ? (booking?.createdBy?.firstName + " " +booking?.createdBy?.lastName) : ""
                                row.actFee = agentActOwner ? 0 : currentProfile?.currencySymbol + currencyHelper.formatMoney(booking.actFee, 2);
                                row.action =
                                currentProfile?.type != ProfileType.Musician.ordinal || booking.status === BookingStatusType.Draft.ordinal  ? (
                                        <Fragment>
                                            <Button color="primary" size="small" onClick={e => this.setActionAnchor(e.currentTarget, booking.id)}>
                                                Actions
                                            </Button>
                                            {booking.id && (
                                                <Menu
                                                    open={this.state.actionAnchor[booking.id] != null}
                                                    onClose={() => this.unsetActionAchnor(booking.id)}
                                                    anchorEl={this.state.actionAnchor[booking.id]}
                                                >
                                                    <MenuItem
                                                        button
                                                        className="booking-details"
                                                        onClick={() => {
                                                            let redirectUrl =
                                                                booking.type === BookingType.Adhoc.ordinal
                                                                    ? `/privateBooking/${booking.id}/edit` + bookingRequestUrl
                                                                    : currentProfile?.type === ProfileType.Musician.ordinal
                                                                        ? `/musician/booking/${booking.id}/details`
                                                                        : agentActOwner
                                                                            ? `/musician/booking/${booking.id}/details`
                                                                            : currentProfile?.type === ProfileType.Agent.ordinal
                                                                                ? `/booking/${booking.id}/edit`
                                                                                : currentProfile?.type === ProfileType.Staff.ordinal
                                                                                    ? `/booking/${booking.id}/edit`
                                                                                    : "";
                                                            this.props.history.push(redirectUrl);
                                                        }}
                                                    >
                                                        {booking.createdById === currentProfile?.id || booking.isBookingVenueManagemenet || currentProfile?.type === ProfileType.Agent.ordinal ? "View / Edit" : "View"}
                                                    </MenuItem>
                                                    {(booking.createdById === currentProfile?.id || booking.isBookingVenueManagemenet || currentProfile?.type === ProfileType.Agent.ordinal)? <MenuItem onClick={() => this.handleOpenDuplicateBookingModal(booking)}>Duplicate Booking</MenuItem> : null}
                                                    {(booking.createdById === currentProfile?.id || booking.isBookingVenueManagemenet || currentProfile?.type === ProfileType.Agent.ordinal)  && booking.status === BookingStatusType.Declined.ordinal ? <MenuItem onClick={() => this.handleOpenChangeActModal(booking)}>Change Roster</MenuItem> : null}
                                                    {((booking.createdById === currentProfile?.id || booking.isBookingVenueManagemenet  || currentProfile?.type === ProfileType.Agent.ordinal) && (booking.status != BookingStatusType.Resent.ordinal && booking.status != BookingStatusType.SentToAct.ordinal) ) ? <MenuItem onClick={() => this.handleOpenDeleteBookingModal(booking)}>Delete Booking</MenuItem> : null}
                                                    {(booking.status === BookingStatusType.SentToAct.ordinal || status === "SentToAct" ) ? <MenuItem onClick={() => this.handleSendReminder(booking)}>Resend booking request</MenuItem> : null}
                                                    {(status === BookingStatusType.Resent.ordinal || status === "Resent" ) ? <MenuItem onClick={() => this.handleSendReminderRequest(booking)}>Resend booking request</MenuItem> : null}
                                                </Menu>
                                            )}
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            {booking.id && (
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    className="action-button booking-details"
                                                    component={Link}
                                                    to={
                                                        booking.type === BookingType.Adhoc.ordinal
                                                            ? `/privateBooking/${booking.id}/edit` + bookingRequestUrl
                                                            : currentProfile?.type === ProfileType.Musician.ordinal
                                                                ? `/musician/booking/${booking.id}/details` + bookingRequestUrl
                                                                : agentActOwner
                                                                    ? `/musician/booking/${booking.id}/details` + bookingRequestUrl
                                                                    : currentProfile?.type === ProfileType.Agent.ordinal
                                                                        ? `/booking/${booking.id}/edit`
                                                                        : currentProfile?.type === ProfileType.Staff.ordinal
                                                                            ? `/booking/${booking.id}/edit`
                                                                            : ""
                                                    }
                                                >
                                                    {booking.createdById === currentProfile?.id || booking.isBookingVenueManagemenet || currentProfile?.type === ProfileType.Agent.ordinal ? "View / Edit" : "View"}
                                                </Button>
                                            )}
                                        </Fragment>
                                    );
                                row.status = (
                                    <BookingStatusBadge
                                        cancelledBy={booking.cancelledBy ? booking.cancelledBy.type : 0}
                                        status={this.getBookingStatus(booking)}
                                    />
                                );
                                return row;
                            })}
                            footerRow = {
                                currentProfile?.type === ProfileType.Agent.ordinal || currentProfile?.type === ProfileType.Staff.ordinal || currentProfile?.type === ProfileType.Musician.ordinal ? 
                                    this.renderFooterRow() : undefined
                            }
                        />
                    </Grid>
                    <Grid item style={{marginTop: 25}} xs={12} key="more" alignContent="center" alignItems="center" justify="center">
                        <Grid container direction="row" justify="center" alignItems="center">
                            {this.props.bookingRequestStore.showMoreRequest ? (
                                <Button variant="outlined" size="large" color="primary" onClick={this.handleShowMore}>
                                    Show More
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
                <ConfirmationModal
                    open={this.state.duplicateModalState}
                    message={`Are you sure you want to duplicate this booking - ${this.state.duplicateBooking?.title}?`}
                    closeHandler={this.handleCloseDuplicateBookingModal}
                    confirmationHandler={this.handleDupicateRequest}
                    declineHandler={this.handleCloseDuplicateBookingModal}
                    confirmationLabel="Duplicate"
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDuplicateSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarEditClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Booking {this.state.duplicateBooking?.title} duplicated</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />

<ConfirmationModal
                    open={this.state.deleteModalState}
                    title={"Delete confirmation."}
                    message={`This cannot be undone.  Would you like to delete this booking?`}
                    closeHandler={this.handleCloseDeleteBookingModal}
                    confirmationHandler={this.handleDeleteRequest}
                    declineHandler={this.handleCloseDeleteBookingModal}
                    confirmationLabel="Delete"
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.openDeleteSnackbar}
                    autoHideDuration={4000}
                    onClose={this.handleSnackbarDeleteClose}
                    SnackbarContentProps={{
                        "aria-describedby": "message-id"
                    }}
                    message={<span id="message-id">Booking {this.state.deleteBooking?.title} deleted</span>}
                    action={
                        <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleSnackbarEditClose}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <BookingChangeActModal open={this.state.changeActModalState} onCloseModel={this.handleCloseChangeActModalWithoutAction} onClose={this.handleCloseChangeActModal} booking={this.state.booking} />
            </Fragment>
        );
    }
}

export default BookingRequests;
