import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import { Select, MenuItem, Grid } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DatePicker } from "material-ui-pickers";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import "./StaffFilter.css";
import SelectField from "../fields/SelectField";

const dateFormat = "DD-MM-YYYY";
const monthFormat = "MMM YYYY";

@inject("profileStore", "venueStore", "staffFilterStore", "venueActStore", "bookingRequestStore")
@observer
class StaffFilter extends Component {
    postChangeInterval = undefined;

    constructor(props) {
        super(props);
        this.state = {
            dashboardFilter: "monthly",
            showDateRangePicker: false,
            startDate: moment().startOf("month"),
            endDate: moment().endOf("month"),
            organisations: [],
            states: [],
            regions: [],
            venues: [],
            acts: [],
            level1Label: "Organisation",
            level2Label: "State",
            level3Label: "Region",
            allVenues: [],
            allRegions: [],
            allStates: []
        };
    }

    componentDidMount() {
        Promise.all([
            this.props.venueActStore.getStaffActRosterMyGigs(null, true),
            this.props.venueStore.getUserVenuesBasedOnPermissionSimple()
        ]).then( response => {
            let actRoster = response[0];
            let venues = response[1];
            if(actRoster){
                this.mapActToSuggestions(actRoster);
            }
            if (venues) {
                this.mapVenuesToSuggestions(venues);
            }
        })
    }
   
    mapBookingCreator = () => {
        let { bookingCreators } = this.props.bookingRequestStore;
        let creators = [{
            value: 0,
            label: "All Booking Creators"
        }];
        bookingCreators.forEach(bookingCreator => {
            creators.push({
                value: bookingCreator.id,
                label:  bookingCreator ? (bookingCreator?.firstName + " " +bookingCreator?.lastName) : ""
            })
        })

        return creators;
    }
    mapActToSuggestions = actRosters => {
        let acts = [];

        actRosters.forEach(actRoster => {
            if(!acts.find(x => x.id === actRoster.actId)){
                acts.push(actRoster.act);
            }
        });

        acts.sort((a, b) => (a.actName < b.actName ? -1 : 1))

        this.setState({
            ...this.state,
            acts: acts
        })
    }

    mapVenuesToSuggestions = venues => {
        var organisations = [];
        var states = [];
        var regions = [];
        var level1Label = "Organisation";
        var level2Label = "State";
        var level3Label = "Region";

        venues.forEach(venue => {
            if (!organisations.find(x => x.id === venue.organisationId)) {
                if (!_.isEmpty(venue.organisation) && venue.organisation) {
                    organisations.push(venue.organisation);
                }
            }
            if (!states.find(x => x.id === venue.stateId)) {
                if (!_.isEmpty(venue.stateEntity) && venue.stateEntity) {
                    states.push(venue.stateEntity);
                }
            }
            if (!regions.find(x => x.id === venue.regionId)) {
                if (!_.isEmpty(venue.region) && venue.region) {
                    regions.push(venue.region);
                }
            }
        });

        if (organisations.length > 0) {
            level1Label = organisations[0].level1Label;
            level2Label = organisations[0].level2Label;
            level3Label = organisations[0].level3Label;
        }

        this.setState({
            ...this.state,
            organisations,
            states,
            regions,
            venues,
            level1Label,
            level2Label,
            level3Label,
            allVenues: venues,
            allStates: states,
            allRegions: regions
            // organisationFilter: organisations.length === 1 ? organisations[0].id : this.state.organisationFilter,
            // stateFilter: states.length === 1 ? states[0].id : this.state.stateFilter,
            // regionFilter: regions.length === 1 ? regions[0].id : this.state.regionFilter,
            // venueFilter: venues.length === 1 ? venues[0].id : this.state.venueFilter
        });
    };

    postFilterChange = () => {
        const { organisationFilter, stateFilter, regionFilter, venueFilter, startDate, endDate, actFilter, creatorId } = this.props.staffFilterStore;
        var filterData = {
            organisation: organisationFilter,
            state: stateFilter,
            region: regionFilter,
            venue: venueFilter,
            startDate: startDate,
            endDate: endDate,
            act: actFilter,
            creatorId: creatorId
        };

        let currentState = this.state;
        if (organisationFilter) {
            currentState.venues = currentState.allVenues.filter(v => v.organisationId === organisationFilter);
        } else if (stateFilter) {
            currentState.regions = currentState.allRegions.filter(r => r.stateId === stateFilter);
        } else if (regionFilter) {
            currentState.venues = currentState.allVenues.filter(v => v.regionId === regionFilter);
        }

        this.setState(currentState)

        if (this.postChangeInterval) {
            clearInterval(this.postChangeInterval);
        }

        this.postChangeInterval = setInterval(() => {
            this.props.postChangeEvent(filterData);
            clearInterval(this.postChangeInterval);
        }, 1000);
    };

    onChange = e => {
        this.props.staffFilterStore.setFilterType(e.target.value);
        this.postFilterChange();
    };

    onChangeAct = value => {
        this.props.staffFilterStore.setActFilter(value);
        this.postFilterChange();
    }

    onChangeCreators = value => {
        this.props.staffFilterStore.setCreatorFilter(value);
        this.postFilterChange();
    }
    
    onOrganisationChange = e => {
        const { organisations } = this.state;
        let organisation = organisations.find(x => x.id === e.target.value);

        this.props.staffFilterStore.setOrganisationFilter(e.target.value);
        this.props.staffFilterStore.setStateFilter(0);
        this.props.staffFilterStore.setRegionFilter(0);
        this.props.staffFilterStore.setVenueFilter(0);
        this.postFilterChange();

        this.setState(
            {
                level1Label: organisation ? organisation.level1Label : "Organisation",
                level2Label: organisation ? organisation.level2Label : "State",
                level3Label: organisation ? organisation.level3Label : "Region"
            },
            () => {
                this.postFilterChange();
            }
        );
    };

    onStateChange = e => {
        this.props.staffFilterStore.setStateFilter(e.target.value);
        this.props.staffFilterStore.setRegionFilter(0);
        this.props.staffFilterStore.setVenueFilter(0);
        this.postFilterChange();
    };

    onRegionChange = e => {
        this.props.staffFilterStore.setRegionFilter(e.target.value);
        this.props.staffFilterStore.setVenueFilter(0);
        this.postFilterChange();
    };

    onVenueChange = e => {
        this.props.staffFilterStore.setVenueFilter(e.target.value);
        this.postFilterChange();
    };

    onChangeStartDate = value => {
        const { endDate } = this.props.staffFilterStore;
        this.props.staffFilterStore.setStartDate(value);
        if (moment(value).endOf("day") > moment(endDate).endOf("day")) {
            this.props.staffFilterStore.setEndDate(value);
        }

        this.postFilterChange();
    };

    onChangeEndDate = value => {
        const { startDate } = this.props.staffFilterStore;
        this.props.staffFilterStore.setEndDate(value);
        if (moment(value).endOf("day") < moment(startDate).endOf("day")) {
            this.props.staffFilterStore.setStartDate(value);
        }
        this.postFilterChange();
    };

    onChangeMonth = value => {
        const startDate = moment(value).date(1);
        this.props.staffFilterStore.setStartDate(startDate);

        const endDate = moment(value).date(moment(value).daysInMonth());
        this.props.staffFilterStore.setEndDate(endDate);

        this.postFilterChange();
    };

    getFilteredStates = () => {
        const { states, organisationFilter } = this.state;
        var filteredStates = [...states];
        if (organisationFilter && organisationFilter !== 0) {
            filteredStates = states.filter(x => x.organisationId === organisationFilter);
        }

        if (filteredStates.length === 1) {
            const firstState = filteredStates[0];
            if (this.state.stateFilter !== firstState.id) {
                this.setState({
                    ...this.state,
                    stateFilter: firstState.id
                });
            }
        }
        return filteredStates;
    };

    getFilteredRegion = () => {
        const { regions, stateFilter, organisationFilter } = this.state;

        var filteredStates = this.getFilteredStates();

        var filteredRegion = [...regions];

        if (stateFilter && stateFilter !== 0) {
            filteredRegion = regions.filter(x => x.stateId === stateFilter);
        }

        if (organisationFilter) {
            filteredRegion = regions.filter(x => filteredStates.find(y => y.id === x.stateId));
        }

        if (filteredRegion.length === 1) {
            const firstRegion = filteredRegion[0];
            if (this.state.regionFilter !== firstRegion.id) {
                this.setState({
                    ...this.state,
                    regionFilter: firstRegion.id
                });
            }
        }

        return filteredRegion;
    };

    getFilteredVenues = () => {
        const { venues, organisationFilter, stateFilter, regionFilter } = this.state;

        var filteredVenues = [...venues];

        if (organisationFilter && organisationFilter !== 0) {
            filteredVenues = filteredVenues.filter(x => x.organisationId === organisationFilter);
        }

        if (stateFilter && stateFilter !== 0) {
            filteredVenues = filteredVenues.filter(x => x.stateId === stateFilter);
        }

        if (regionFilter && regionFilter !== 0) {
            filteredVenues = filteredVenues.filter(x => x.regionId === regionFilter);
        }

        if (filteredVenues.length === 1) {
            const firstVenue = filteredVenues[0];
            if (this.state.venueFilter !== firstVenue.id) {
                this.setState({
                    ...this.state,
                    venueFilter: firstVenue.id
                });
            }
        }

        return filteredVenues;
    };

    mapActSuggestions = () => {
        const { acts } = this.state;
        let suggestions = [{
            value: 0,
            label: "All Acts"
        }];

        acts.forEach(act => {
            suggestions.push({
                value: act.id,
                label: act.actName
            })
        })

        return suggestions;
    }

   

    render() {
        const { organisations, states, regions, venues, level1Label, level2Label, level3Label } = this.state;
        const { showActFilter } = this.props;
        const { filterType, startDate, endDate, organisationFilter, stateFilter, regionFilter, venueFilter, actFilter, creatorId } = this.props.staffFilterStore;

        return (
            <Grid container spacing={8}>
                  {
                  showActFilter ? (<Grid xs = { 12 } lg = {3} item className="filterHalf">
                        <SelectField 
                            suggestions={this.mapBookingCreator()}
                            onChange={this.onChangeCreators}
                            value={creatorId}
                            placeholder="Select booking creator"
                            color='#A9A9A9'
                            classes={{
                                root: "pull-right dark-select slim-input",
                                icon: "text-white",
                                input: "text-primary"
                            }}
                        />
                    </Grid>) : null
                }
                {
                  showActFilter ? (
                    <Grid xs = { 12 } lg = {2} item className="filterHalf">
                        <SelectField 
                            suggestions={this.mapActSuggestions()}
                            onChange={this.onChangeAct}
                            value={actFilter}
                            placeholder="Select Act"
                            color='#A9A9A9'
                            classes={{
                                root: "pull-right dark-select slim-input",
                                icon: "text-white",
                                input: "text-primary"
                            }}
                        />
                    </Grid>
                  ) : null
                }
                {organisations &&
                    organisations.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="Organisation"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={organisationFilter}
                                onChange={this.onOrganisationChange}
                                inputProps={{
                                    color: "primary"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All {`${level1Label}s` || "Organisations"}</MenuItem>
                                {organisations.map(organisation => (
                                    <MenuItem key={organisation.id} value={organisation.id}>
                                        {organisation.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                {states &&
                    states.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="State"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={stateFilter}
                                onChange={this.onStateChange}
                                inputProps={{
                                    color: "primary"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All {`${level2Label}s` || "States"}</MenuItem>
                                {this.getFilteredStates().map(state => (
                                    <MenuItem key={state.id} value={state.id}>
                                        {state.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                {regions &&
                    regions.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="Region"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={regionFilter}
                                onChange={this.onRegionChange}
                                inputProps={{
                                    color: "primary"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All {`${level3Label}s` || "Regions"}</MenuItem>
                                {this.getFilteredRegion().map(region => (
                                    <MenuItem key={region.id} value={region.id}>
                                        {region.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                {venues &&
                    venues.length > 0 && (
                        <Grid item className="filterHalf">
                            <Select
                                label="Venue"
                                className="pull-right dark-select slim-input"
                                fullWidth
                                value={venueFilter}
                                onChange={this.onVenueChange}
                                inputProps={{
                                    color: "primary"
                                }}
                                classes={{
                                    root: "text-white",
                                    icon: "text-white"
                                }}
                            >
                                <MenuItem value={0}>All Venues</MenuItem>
                                {this.getFilteredVenues().map(venue => (
                                    <MenuItem key={venue.id} value={venue.id}>
                                        {venue.profileName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    )}
                <Grid item className="filterFull">
                    <Select
                        label="Filter"
                        className="dark-select slim-input"
                        fullWidth
                        value={filterType}
                        onChange={this.onChange}
                        inputProps={{
                            color: "primary"
                        }}
                        classes={{
                            root: "text-white",
                            icon: "text-white"
                        }}
                    >
                        {/* {this.props.hideAllTime ? null : <MenuItem value="all">All-Time</MenuItem>}  */}
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="week">This Week</MenuItem>
                        <MenuItem value="lastweek">Last Week</MenuItem>
                        {/* <MenuItem value="month">This Month</MenuItem>
                        <MenuItem value="nextMonth">Next Month</MenuItem> */}
                        <MenuItem value="monthly">Month</MenuItem>
                        <MenuItem value="custom">Custom</MenuItem>
                    </Select>
                </Grid>
                {filterType === "custom" && (
                    <Grid item className="filterHalfd">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                emptyLabel="From"
                                margin="none"
                                className="dark-datepicker slim-input"
                                value={startDate}
                                onChange={this.onChangeStartDate}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                format={dateFormat}
                                InputProps={{
                                    classes: {
                                        root: "text-white"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {filterType === "custom" && (
                    <Grid item className="filterHalfd">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                emptyLabel="to"
                                margin="none"
                                className="dark-datepicker slim-input"
                                value={endDate}
                                onChange={this.onChangeEndDate}
                                format={dateFormat}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                InputProps={{
                                    classes: {
                                        root: "text-white"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
                {filterType === "monthly" && (
                    <Grid item className="filterFull">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                views={["year", "month"]}
                                emptyLabel="..."
                                margin="none"
                                className="dark-datepicker slim-month-input"
                                value={startDate}
                                onChange={this.onChangeMonth}
                                format={monthFormat}
                                keyboardIcon={<EventIcon />}
                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                fullWidth
                                autoOk
                                InputProps={{
                                    classes: {
                                        root: "text-white pull-right"
                                    }
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default StaffFilter;
