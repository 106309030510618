import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BookingSocial from "../../components/booking/BookingSocial";
import Breadcrumb from "../../components/Breadcrumb";
import Header from "../../components/Header";
import Heading from "../../components/Heading";
import Loading from "../../components/loadingSpinner/Loading";
import MainContainer from "../../components/MainContainer";
import ReportDateFilterGigs from "../../components/reports/ReportDateFilterGigs";
import Sidebar from "../../components/Sidebar";
import { ProfileType } from "../../types/enum";
import "./SocialPosts.css";



const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("templateStore", "profileStore", "bookingRequestStore", "reportStore")
@withRouter
@observer
class SocialPosts extends Component {
    state = {
        tag: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            tag: '',

        };
        this.props.bookingRequestStore.resetRequestsUpcomingCompleted();
        this.props.reportStore.resetFilterData();
    }

    componentWillUnmount() {
        this.props.reportStore.resetFilterData();
    }

    loadGigs = () => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.resetSocials()
        this.props.bookingRequestStore.showLoadingState();
        this.props.bookingRequestStore.loadSocial(currentProfile?.id,1).then(() => {
            this.props.bookingRequestStore.hideLoadingState();
        })
    }

    componentDidMount = () => {
        const currentProfile = this.props.profileStore.getCurrentProfile();
        this.renderTag(currentProfile)
        
        this.props.bookingRequestStore.showLoadingState();
    };

    renderTag = (profile) => {
        switch (profile.type) {
            case ProfileType.Musician.ordinal:
                this.setState({tag: "musician"})
                break;
            case ProfileType.Agent.ordinal:
                this.setState({tag: "agent"})
                break;
            case ProfileType.Staff.ordinal:
                this.setState({tag: "staff"})
                break;
        
            default:
                break;
        }
    }

    render() {
        const breadcrumb = [{ name: "Dashboard", link: `/${this.state.tag}` }, { name: "Social Posts" }];
        let { isLoading: isLoadingGigs } = this.props.bookingRequestStore;

        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title="Social Posts">
                        <ReportDateFilterGigs postChangeEvent={this.loadGigs} isSocialPost={true} />
                    </Heading>
                    <Paper style={{ position: "relative" }} className="booking-paper-form">
                        <Loading showed={isLoadingGigs} />
                        <div style={{ padding: 16 }}>
                            <BookingSocial profileType={`${this.state.tag}`} social />
                        </div>
                    </Paper>
                </MainContainer>
            </div>
        );
    }
}

SocialPosts.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(SocialPosts);
