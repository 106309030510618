import React, { Component } from "react";

import { inject, observer } from "mobx-react";

import { Grid, Paper } from "@material-ui/core";

import WidgetContainer from "../widget/WidgetContainer.js";
import ResponsiveTable from "../table/ResponsiveTable";
import moment from "moment";

import "./UpcomingBooking.css";
import { Link } from "react-router-dom";
import { BookingType, LineupStatusType, ProfileType, RosterDividendType } from "../../types/enum.js";
import currencyHelper from '../../helper/currency';
@inject("profileStore", "bookingRequestStore", "templateStore")
@observer
class UpcomingBookingMusician extends Component {
    state = {
        venue: "null",
        consultant: "null",        
        columns: [
            { key: "date", label: "Date", width: "15%", sortable: true, isDate:true, addComp:true, style: {whiteSpace: 'nowrap'} },
            { key: "venueName", label: "Venue", width: "20%" },
            { key: "actName", label: "Artist", width: "20%" },
            { key: "gigFee", label: "Venue/Client Fee", sortable: true, isNumber:true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
            { key: "agentCommissionAmount", label: "Agent Fee", sortable: true, isNumber: true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
            { key: "myFee", label: "My Fee", sortable: true, isNumber:true, width: "10%", align: 'left', style: { flexDirection: 'row' } },
            { key: "agentContact", label: "Booking Creator", width: "15%", align: 'left', style: { whiteSpace: 'nowrap' } }
        ]
    };

    tableData = {
        title: ["Date", "Venue", "Address", "Artist", "Time", "Consultant"]
    };

    mapBookingDataToRowData = () => {
        const { tableData } = this.props
        const { currentProfile } = this.props.profileStore;
        
        if (tableData && currentProfile) {
            return tableData.map(booking => {
                var row = {};
                let time = booking.timeStart.length < 8 ? `0${booking.timeStart}` : booking.timeStart;                
                row.date = {value:`${booking.dateStart.format("DD MMM YYYY")} ${moment(time).format('hh.mm A')}`, newFormat: <Link to={this.bookingRedirectPath(booking)} target={"_blank"}>{booking.dateStart.format("DD MMM YYYY")} {moment(time).format('hh.mm A')}</Link>};
                row.venueName = (
                    <div>
                        <span>{booking.venue && booking.venueId ? booking.venue.profileName : booking.venueName}</span>
                        <br />
                        <small>{booking.venueAddress}</small>
                    </div>
                );

                const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;

                row.actName = <Link to={`/profile/${isEntertainerBookings ? currentProfile?.id : booking.act?.id}`} target={"_blank"}>{isEntertainerBookings ? `${currentProfile?.firstName} ${currentProfile?.lastName}` : booking.act?.actName}</Link>
                let totalFee = 0;
                booking?.lineups?.forEach(l => {
                    if (l.status !== LineupStatusType.Removed.ordinal) {
                        totalFee += parseFloat(l.fee)
                    }                                    
                });  
                let linuep = booking?.lineups?.find(x => x.profileId === currentProfile?.id);

                var musicianFee = parseFloat(booking.gigFee) - totalFee

                let adminFeeRoster = 0
                let totalRosterFeeAdmin = 0
                booking?.act?.actRoster?.forEach(actRoster => {
                    let feeCount = 0
                    if(actRoster.dividendType === RosterDividendType.Percentage.ordinal){
                        feeCount = actRoster.dividendAmount /100 * booking.actFee;
                    }else {
                        feeCount = actRoster.dividendAmount;
                    }
                    totalRosterFeeAdmin = totalRosterFeeAdmin + feeCount
                })
                adminFeeRoster = booking.actFee - totalRosterFeeAdmin
                if(currentProfile?.type === ProfileType.Musician.ordinal && !booking?.lineups?.length){
                    let actRoster = booking?.act?.actRoster?.filter(x => x.rosters.some(t => t.profileId === currentProfile?.id));
                    if(actRoster?.length){
                        if(actRoster[0].dividendType === RosterDividendType.Percentage.ordinal){
                            musicianFee = actRoster[0].dividendAmount /100 * booking.actFee;
                        }else {
                            musicianFee = actRoster[0].dividendAmount;
                        }
                            if(booking?.act?.actAdminId === currentProfile?.id){
                                musicianFee = parseFloat(musicianFee) + parseFloat(adminFeeRoster)
                            }
                    }else{
                        musicianFee = booking.actFee
                        booking?.act?.actRoster?.forEach(item => {
                            var rosterFee = 0
                            if(item.dividendType === RosterDividendType.Percentage.ordinal){
                                rosterFee = item.dividendAmount /100 * booking.actFee;
                            }else {
                                rosterFee = item.dividendAmount;
                            }
                            musicianFee = musicianFee - rosterFee
                        });
                    }
                }
                 
                var totalRosterFee = 0

                booking?.lineups?.forEach(lineUp => {
                    totalRosterFee = parseFloat(totalRosterFee) + parseFloat(lineUp.fee) 
                })
                var adminFee = booking.actFee - totalRosterFee
                var calculateFee = linuep?.fee ?  linuep?.fee : 0
                    if(booking?.act?.actAdminId === currentProfile?.id){
                        calculateFee = parseFloat(calculateFee) + parseFloat(adminFee)
                    }
                
                let myFee = linuep?.fee ? calculateFee: isEntertainerBookings ? booking.gigFee : (booking.isAdmin && !linuep) ? musicianFee : booking.actFee;
               

                let actOwner = booking.act?.userId === currentProfile?.userId;
                
                row.gigFee = actOwner && ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$") + parseFloat(booking.gigFee).toLocaleString()
                row.myFee = ((currentProfile && currentProfile.currencySymbol) ? currentProfile.currencySymbol : "$") + parseFloat(myFee).toLocaleString();
                row.agentCommissionAmount = `${currentProfile?.currencySymbol}${currencyHelper.formatMoney(booking.agentCommissionAmount, 2)}`      
                let isAgency = booking.createdBy.type === ProfileType.Agent.ordinal && booking.provider;
                row.agentContact = isAgency ? booking.provider : booking.createdBy.firstName + " " + booking.createdBy.lastName;
                
                return row;
            })
        }
        else {
            return [];
        }
    };

    bookingRedirectPath = (booking) => {
        const { currentProfile } = this.props.profileStore;
        const isEntertainerBookings = currentProfile?.type === ProfileType.Musician.ordinal && booking.createdById === currentProfile?.id && !booking.act?.actName;
        let path = `/booking/${booking.id}/edit`
        if (booking.type === BookingType.Adhoc.ordinal) path = `/privateBooking/${booking.id}/edit`;
        else if (isEntertainerBookings) path = `/booking/${booking.id}/edit`
        else if (currentProfile.type === ProfileType.Musician.ordinal) path = `/musician/booking/${booking.id}/details`;

        return path;
    };

    handleVenueChange = event => {
        this.setState({ venue: event.target.value });
    };

    handleConsultantChange = event => {
        this.setState({ consultant: event.target.value });
    };

    render() {
        return (
            <Paper>
                <WidgetContainer
                    title="Upcoming Gigs"
                    showBadge={this.props.tableData && this.props.tableData.length ? true : false}
                    badgeValue={this.props.tableData && this.props.tableData.length}
                >
                    <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <ResponsiveTable
                                sortable={true}
                                columns={this.state.columns}
                                rows={this.mapBookingDataToRowData()}
                            />
                        </Grid>
                    </Grid>
                </WidgetContainer>
            </Paper>
        );
    }
}

export default UpcomingBookingMusician;
