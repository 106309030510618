import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import MainContainer from "../../components/MainContainer";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import General from "../../components/profile/General";
import Design from "../../components/profile/Design";
import Social from "../../components/profile/Social";
import Financial from "../../components/profile/Financial";
import Insurance from "../../components/profile/Insurance";
import Privacy from "../../components/profile/Privacy";
import Embed from "../../components/profile/Embed";
import Breadcrumb from "../../components/Breadcrumb";
import Heading from "../../components/Heading";
import TermCondition from "../../components/profile/TermCondition";

import "../stepper.css";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import { ProfileType } from "../../types/enum";

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("templateStore", "profileStore", "agentActStore","instagramStore")
@observer
class EditAgent extends Component {
    breadcrumb = [{ name: "Dashboard", link: "/agent" }, { name: "My Profile" }];

    componentDidMount = () => {
        if(this.props.match.params.new === "instagram"){
            const query = new URLSearchParams(this.props.location.search);
            const token = query.get('code')
            
            this.props.instagramStore.setInstagramCode(token);
            this.props.templateStore.setEditProfileActiveStep(2);
        } else if(this.props.match.params.new === "integration"){
            this.props.templateStore.setEditProfileActiveStep(2);
        }else{
            this.props.templateStore.setEditProfileActiveStep(0);
            this.props.agentActStore.setIsAgentAct(false);
        }
      
    };

    handleNext = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep + 1);
    };

    handleBack = () => {
        const { editProfileActiveStep } = this.props.templateStore;
        this.props.templateStore.setEditProfileActiveStep(editProfileActiveStep - 1);
    };

    handleReset = () => {
        this.props.templateStore.setEditProfileActiveStep(0);
    };

    handleStepClick = step => () => {
        this.props.templateStore.setEditProfileActiveStep(step);
    };

    getStepContent(stepIndex) {
        const { profile, currentProfile } = this.props.profileStore;
        const { match } = this.props;

        const isConsultant = this.props.match.params.role==="consultant" || profile?.isConsultant;        
       
        if (
            !profile || 
             profile.type === ProfileType.Agent.ordinal || this.props.match.params.role || currentProfile?.type === ProfileType.Agent.ordinal ||
            (profile.type !== ProfileType.Agent.ordinal && match && match.params && match.params.new)
        ) {

            if(isConsultant){

                switch (stepIndex) {
                    case 0:
                        return <General type="agent" profileId={currentProfile && currentProfile?.id}/>;
                    case 1:
                        return <Design type="agent" profileId={currentProfile && currentProfile?.id}/>;
                    case 2:
                        return <Privacy type="agent" profileId={currentProfile && currentProfile?.id}/>;
                    default:
                        return "Uknown stepIndex";
                }

            }else{
                
                switch (stepIndex) {
                    case 0:
                        return <General type="agent" />;
                    case 1:
                        return <Design type="agent" />;
                    case 2:
                        return <Social type="agent" />;
                    case 3:
                        return <Financial type="agent" />;
                    case 4:
                        return <Insurance type="agent" profileId={profile?.id}/>;
                    case 5:
                        return <TermCondition type="agent" profileId={profile?.id} />;
                    case 6:
                        return <Privacy type="agent" />;
                    case 7:
                        return <Embed type="agent" />;
                    default:
                        return "Uknown stepIndex";
                }
               
            }

        }
    }

    render() {
        const { profile } = this.props.profileStore;
        const { classes, match } = this.props;
        const steps = this.props.match.params.role=="consultant" || profile?.isConsultant ? this.props.templateStore.getSteps("consultant") : this.props.templateStore.getSteps("agent");
        const { editProfileActiveStep } = this.props.templateStore;
        const isAgent = profile && profile.type === ProfileType.Agent.ordinal;
        const roleProfile = this.props.match.params.role==="consultant" || profile?.isConsultant ? "Consultant":"Agent"
        // const isEditConsultant = window.location.pathname.includes("/agent/edit/new/consultant")

        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={this.breadcrumb} />
                    <Heading
                        title={
                            profile && !profile.isCompleted || match.params.new === "new" && match.params.role === "consultant"
                                ? `Create ${roleProfile} Profile`
                                : `Edit ${roleProfile} Profile`
                        }
                    />
                    <Hidden smDown>
                        <Stepper nonLinear={profile && profile.isCompleted} activeStep={editProfileActiveStep} alternativeLabel={false}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    <Hidden mdUp xsDown>
                        <Stepper nonLinear={profile && profile.isCompleted} activeStep={editProfileActiveStep} alternativeLabel={true}>
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepButton onClick={this.handleStepClick(index)}>{label}</StepButton>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    <Hidden smUp>
                        <Stepper
                            nonLinear={profile && profile.isCompleted}
                            activeStep={editProfileActiveStep}
                            alternativeLabel={false}
                            className="stepperXs"
                        >
                            {steps.map((label, index) => {
                                return (
                                    <Step key={label}>
                                        <StepButton onClick={this.handleStepClick(index)} />
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Hidden>
                    {editProfileActiveStep === steps.length ? (
                        <Paper className="stepperContent">
                            <Typography className={classes.instructions} component="h3">
                                All steps completed - you're profile is ready!
                            </Typography>
                            <Button onClick={this.handleReset}>Reset</Button>
                        </Paper>
                    ) : (
                        <Paper className="stepperContent">{this.getStepContent(editProfileActiveStep)}</Paper>
                    )}
                </MainContainer>
            </div>
        );
    }
}

EditAgent.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(EditAgent);
