import React, { Component, Fragment } from "react";
import { observer, inject } from "mobx-react";

import {
    Button,
    Checkbox,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    FormControlLabel,
    Grid
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ResponsiveTable from "../../table/ResponsiveTable";
import TableRowProfile from "../../util/TableRowProfile";

import { ProfileType, ProfileRelationType } from "../../../types/enum";
import EditAgentModal from "../AgentTable/EditAgentModal";
import AcceptRequestModal from "../AgentTable/AcceptRequestModal";
import ConfirmationModal from "../../modal/ConfirmationModal";
import image from "../../../helper/image";

@inject("agentVenueStore", "templateStore", "userInvitationStore")
@observer
class AgentRosterTable extends Component {
    state = {
        editModalState: false,
        acceptModalState: false,
        deleteRequestModalState: false,
        deleteModalState: false,
        deleteInvitationModalState: false,
        agentVenue: undefined,
        request: undefined
    };

    columns = [{ key: "venue", label: "Venue", width: "20%" }, { key: "agent", label: "Agents", width: "70%" }];

    pendingColumns = [
        { key: "venue", label: "Venue" },
        { key: "agent", label: "Agent" },
        { key: "bookingDay", label: "Booking Day" },
        { key: "status", label: "Status" },
        { key: "action", label: "" }
    ];

    pendingRows = [];
    rows = [];

    mapBookingDay = data => {
        var bookingDay = "";

        if (data?.monday) {
            bookingDay += "Mon, ";
        }
        if (data?.tuesday) {
            bookingDay += "Tue, ";
        }
        if (data?.wednesday) {
            bookingDay += "Wed, ";
        }
        if (data?.thursday) {
            bookingDay += "Thu, ";
        }
        if (data?.friday) {
            bookingDay += "Fri, ";
        }
        if (data?.saturday) {
            bookingDay += "Sat, ";
        }
        if (data?.sunday) {
            bookingDay += "Sun, ";
        }

        return bookingDay.substr(0, bookingDay.length - 2);
    };

    handleToggleManage = (checked, data) => {
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.updateManagePermision(checked, data.id).then(() => {
            this.props.templateStore.hideLoadingScreen();
        });
    };

    openEditModal = agentVenue => {
        this.setState(
            {
                ...this.state,
                editModalState: true
            },
            () => {
                this.props.agentVenueStore.setEditedAgentVenue(agentVenue);
            }
        );
    };

    closeEditModal = () => {
        this.setState(
            {
                ...this.state,
                editModalState: false
            },
            () => {
                this.props.agentVenueStore.setEditedAgentVenue(undefined);
            }
        );
    };

    openAcceptModal = request => {
        this.setState({
            ...this.state,
            acceptModalState: true,
            request: request
        });
    };

    closeAcceptModal = () => {
        this.setState({
            ...this.state,
            acceptModalState: false
        });
    };

    handleDelete = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.deleteAgentVenue(this.state.agentVenue.id).then(() => {
            this.props.templateStore.openSnackbar("Agent has been removed.");
            this.props.templateStore.hideLoadingScreen();
            this.closeDeleteModal();
        });
    };

    handleDeleteRequest = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.agentVenueStore.deleteRequestByStaff(this.state.request).then(() => {
            this.props.templateStore.openSnackbar("Agent request has been removed.");
            this.props.templateStore.hideLoadingScreen();
            this.closeDeleteModal();
        });
    };

    handleDeleteInvitation = () => {
        this.props.templateStore.showLoadingScreen();
        this.props.userInvitationStore.deleteInvitation(this.state.request).then(() => {
            this.props.userInvitationStore.getMyUserInvitation(ProfileRelationType.AgentVenue.ordinal).then(() => {
                this.props.templateStore.openSnackbar("Agent invitation has been removed.");
                this.props.templateStore.hideLoadingScreen();
                this.closeDeleteModal();
            });
        });
    };

    openDeleteModal = data => {
        this.setState({
            ...this.state,
            deleteModalState: true,
            agentVenue: data
        });
    };

    openDeleteRequestModal = data => {
        this.setState({
            ...this.state,
            deleteRequestModalState: true,
            request: data
        });
    };

    openDeleteInvitationModal = data => {
        this.setState({
            ...this.state,
            deleteInvitationModalState: true,
            request: data
        });
    };

    closeDeleteModal = () => {
        this.setState({
            ...this.state,
            deleteRequestModalState: false,
            deleteModalState: false,
            deleteInvitationModalState: false,
            agentVenue: undefined,
            request: undefined
        });
    };

    extractIntialFromName = name => {
        if (!name) {
            return false;
        }
        var split = name.split(" ");
        if (split) {
            var initial = "";
            if (split[0]) {
                initial += split[0].substring(0, 1);
            }
            if (split[1]) {
                initial += split[1].substring(0, 1);
            }
            return initial.toUpperCase();
        }
    };

    mapInvitedAgentToRowData = () => {
        this.pendingRows = [];
        this.props.userInvitationStore.invitations.forEach(data => {
            var row = {};
            var jsonBookingDay = JSON.parse(data.additionalData);

            row.venue = <TableRowProfile profileId={data.venue?.id} name={data.venue?.fullName} avatarUrl={image.getProfileImage(data.venue)}/>;

            row.agent = <TableRowProfile avatarUrl={image.getProfileImage(data.agent)} name={`${data.invitedFirstName} ${data.invitedLastName} - ${data.invitedEmail}`}/>;

            row.bookingDay = this.mapBookingDay(jsonBookingDay);

            row.status = "Invited";

            row.style = {
                backgroundColor: "#EEE"
            };
            if(this.props.allowBooking){
                row.action = (
                    <Button size="small" color="primary" onClick={() => this.openDeleteInvitationModal(data)}>
                        Remove
                    </Button>
                );
            }else{
                row.action = (
                    ""
                );
            }
          
            this.pendingRows.push(row);
        });
        return Promise.resolve();
    };

    mapPendingAgentToRowData = () => {
        this.props.agentVenueStore.pendingAgentVenues.forEach(data => {
            var row = {};
            if(data.id) {
                var jsonBookingDay = JSON.parse(data.rules);
                
                var venue = data.primaryProfile.type === ProfileType.Venue.ordinal ? data.primaryProfile : data.secondaryProfile;
    
                var agent = data.primaryProfile.type === ProfileType.Agent.ordinal ? data.primaryProfile : data.secondaryProfile;
                row.venue = <TableRowProfile profileId={venue.id} name={venue.fullName} avatarUrl={image.getProfileImage(venue)} />;
    
                row.agent = <TableRowProfile profileId={agent.id} name={agent.fullName} avatarUrl={image.getProfileImage(agent)}/>;
    
                row.bookingDay = this.mapBookingDay(jsonBookingDay);
            }


            row.status = "Pending";

            row.style = {
                backgroundColor: "#EEE"
            };
            if(this.props.allowBooking){
                row.action =
                data.primaryProfile && data.primaryProfile.type !== ProfileType.Venue.ordinal ? (
                    <Button size="small" color="primary" className="btn-venue-request" onClick={() => this.openAcceptModal(data)}>
                        View Request
                    </Button>
                ) : (
                    <Button size="small" color="primary" onClick={() => this.openDeleteRequestModal(data)}>
                        Remove
                    </Button>
                );
            }else{
                row.action = ("")
            }
          

            this.pendingRows.push(row);
        });
    };

    mapAgentToRowData = () => {
        this.rows = [];
        this.props.agentVenueStore.groupedPendingAgentVenues.forEach(venue => {
            var row = {};
            row.venue = (
                <TableRowProfile
                    profileId={venue.data[0] && venue.data[0].venue ? venue.data[0].venue.id : undefined}
                    name={venue.data[0].venue.fullName} avatarUrl={image.getProfileImage(venue.data[0].venue)}
                />
            );

            var agents = "";

            venue.data.forEach(data => {
                agents += data.agent.fullName + ", ";
            });

            agents = agents.substr(0, agents.length - 2);
            if(this.props.allowBooking){
                row.agent = (
                    <ExpansionPanel
                        style={{
                            boxShadow: "none"
                        }}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography> {agents} </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={row.style}>
                            <Grid container spacing={8}>
                                {venue.data.map(data => (
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} lg={3}>
                                                <TableRowProfile profileId={data.agent.id} name={data.agent.fullName} avatarUrl={image.getProfileImage(data.agent)} />
                                            </Grid>
                                            <Grid item xs={12} lg={3} style={{ paddingTop: "20px" }}>
                                                {this.mapBookingDay(data)}
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={data.manage}
                                                                onChange={(e, checked) => this.handleToggleManage(checked, data)}
                                                                value={data.manage}
                                                            />
                                                        }
                                                        label="Manage Favourite Act"
                                                    />
                                                    <Button size="small" color="primary" onClick={() => this.openEditModal(data)}>
                                                        Edit
                                                    </Button>
                                                    <Button size="small" color="primary" onClick={() => this.openDeleteModal(data)}>
                                                        Remove
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            }else{
                row.agent = (
                    <ExpansionPanel
                        style={{
                            boxShadow: "none"
                        }}
                    >
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography> {agents} </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={row.style}>
                            <Grid container spacing={8}>
                                {venue.data.map(data => (
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} lg={3}>
                                                <TableRowProfile profileId={data.agent.id} name={data.agent.fullName} avatarUrl={image.getProfileImage(data.agent)} />
                                            </Grid>
                                            <Grid item xs={12} lg={3} style={{ paddingTop: "20px" }}>
                                                {this.mapBookingDay(data)}
                                            </Grid>
                                            <Grid item xs={12} lg={6}>
                                                <div>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={data.manage}
                                                                onChange={(e, checked) => this.handleToggleManage(checked, data)}
                                                                value={data.manage}
                                                                disabled
                                                            />
                                                        }
                                                        label="Manage Favourite Act"
                                                    />
                                                 
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            }
           

            this.rows.push(row);
        });
    };

    render() {
        this.mapInvitedAgentToRowData().then(this.mapPendingAgentToRowData());
        this.mapAgentToRowData();

        return (
            <Fragment>
                {this.pendingRows.length > 0 ? (
                    <Fragment>
                        <ResponsiveTable
                            columns={this.pendingColumns}
                            rows={this.pendingRows}
                            showToolbar={true}
                            title="Pending Request"
                            pagination={this.pendingRows.length > 5}
                        />
                        <br />
                    </Fragment>
                ) : (
                    ""
                )}
                <ResponsiveTable columns={this.columns} rows={this.rows} />
                <EditAgentModal open={this.state.editModalState} onClose={this.closeEditModal} />
                <AcceptRequestModal open={this.state.acceptModalState} onClose={this.closeAcceptModal} request={this.state.request} isStaff={true} />

                <ConfirmationModal
                    title="Delete Agent"
                    open={this.state.deleteInvitationModalState}
                    message="Are you sure you want to delete this agent?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDeleteInvitation}
                    confirmationLabel="Delete"
                />

                <ConfirmationModal
                    title="Delete Agent"
                    open={this.state.deleteRequestModalState}
                    message="Are you sure you want to delete this agent?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDeleteRequest}
                    confirmationLabel="Delete"
                />

                <ConfirmationModal
                    title="Delete Agent"
                    open={this.state.deleteModalState}
                    message="Are you sure you want to delete this agent?"
                    closeHandler={this.closeDeleteModal}
                    confirmationHandler={this.handleDelete}
                    confirmationLabel="Delete"
                />
            </Fragment>
        );
    }
}

export default AgentRosterTable;
