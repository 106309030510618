import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import image from "../../../helper/image";
import { Grid, Button, FormControlLabel, Switch, IconButton, Card, CardHeader, Avatar, TextField, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import RichTextEditor from "../../richTextEditor/RichTextEditor";
import ModalContainer from "../../modal/ModalContainer";
import ProfileSearchBox from "../../fields/ProfileSearchBox";
import TableRowProfile from "../../util/TableRowProfile";
import ResponsiveTable from "../../table/ResponsiveTable";

@inject("profileStore", "venueStore", "templateStore", "agentVenueStore", "userInvitationStore")
@observer
class StaffAgentInvitationModal extends Component {
    state = {
        loading: false,
        inviteNewUser: false
    };

    venueColumns = [{ key: "venue", label: "Venue" }];

    venueRows = [];

    constructor(props) {
        super(props);
        this.props.venueStore.resetVenues();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.open && !this.props.open) {
            this.venueRows = [];
            this.props.templateStore.showLoadingScreen();
            this.props.venueStore.load().then(() => {
                this.props.templateStore.hideLoadingScreen();
            });
            this.setState({
                ...this.state,
                inviteNewUser: false
            });
        }
    }

    mapVenuesToRowData = () => {
        const { inviteNewUser } = this.state;
        const { venues } = this.props.venueStore;
        const { pendingAgentVenues, agentVenues, invitedProfile, invitedVenues } = this.props.agentVenueStore;
        if (venues) {
            this.venueRows = [];
            venues.forEach(data => {
                var row = {};
                row.id = data.id;
                row.disableCheckbox =
                    pendingAgentVenues.filter(
                        x =>
                            (x.primaryProfileId === data.id && invitedProfile && x.secondaryProfileId === invitedProfile.id) ||
                            (x.secondaryProfileId === data.id && invitedProfile && x.primaryProfileId === invitedProfile.id)
                    ).length > 0 ||
                    agentVenues.filter(x => x.venueId === data.id && invitedProfile && x.agentId === invitedProfile.id).length > 0 ||
                    (!invitedProfile && !inviteNewUser);
                row.checked = !row.disableCheckbox ? invitedVenues.filter(x => x.id === data.id).length > 0 : false;
                row.venue = <TableRowProfile name={data.fullName} avatarUrl={image.getProfileImage(data)} profileId={data.id}/>;
                row.venueData = data;
                this.venueRows.push(Object.assign({}, row));
            });
        }
    };

    handleInvite = () => {
        const { inviteNewUser } = this.state;

        this.props.templateStore.showLoadingScreen();

        if (inviteNewUser) {
            this.props.userInvitationStore.inviteStaffAgent().then(
                () => {
                    this.props.agentVenueStore.getStaffVenueAgentRequest().then(() => {
                        this.props.templateStore.hideLoadingScreen();
                        this.props.onClose();
                    })
                },
                err => {
                    this.props.templateStore.openSnackbar(err);
                    this.props.templateStore.hideLoadingScreen();
                }
            );
        } else {
            this.props.agentVenueStore.inviteAgentByStaff().then(() => {
                this.props.agentVenueStore.getStaffVenueAgentRequest().then(() => {
                    this.props.templateStore.hideLoadingScreen();
                    this.props.onClose();
                })
            });
        }
    };

    handleSelect = item => {
        this.props.agentVenueStore.setInvitedProfile(item);
        this.setState({ ...this.state });
    };

    getInvitationMessage = () => {
        return this.props.agentVenueStore.invitationMessage;
    };

    handleInvitationMessageChange = value => {
        this.props.agentVenueStore.setInvitationMessage(value);
    };

    getCheckedVenues = rows => {
        var selectedVenues = [];
        rows.forEach(row => {
            if (!row.disableCheckbox) {
                selectedVenues.push(row.venueData);
            }
        });
        this.props.agentVenueStore.setInvitedVenues(selectedVenues);
    };

    renderDaySwitch = () => {
        return (
            <Grid container spacing={0}>
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(data => (
                    <Grid item xs={3}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.agentVenueStore.dayToBook[data.toLowerCase()]}
                                    onChange={() => this.props.agentVenueStore.toggleDayToBook(data.toLowerCase())}
                                    value={data.toLowerCase()}
                                    color="primary"
                                />
                            }
                            label={data}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    };

    handleNoAgentFound = value => {
        this.props.userInvitationStore.resetInvitedData();
        this.setState(
            {
                ...this.state,
                inviteNewUser: true
            },
            () => {
                var names = value.match(/\S+/g) || [];

                if (names && names[0]) {
                    this.props.userInvitationStore.setInvitedUserFirstName(names[0]);
                }
                if (names && names[names.length - 1]) {
                    this.props.userInvitationStore.setInvitedUserLastName(names[names.length - 1]);
                }
            }
        );
    };

    isInviteButtonDisabled = () => {
        const { inviteNewUser } = this.state;
        const { errors, invitedUserFirstName, invitedUserLastName, invitedUserEmail } = this.props.userInvitationStore;
        const { invitedProfile, invitedVenues } = this.props.agentVenueStore;
        if (inviteNewUser) {
            const isError = errors?.firstName || errors?.lastName || errors?.actName || errors?.email
            return invitedVenues.length === 0 || isError || !invitedUserFirstName || !invitedUserLastName || !invitedUserEmail;
        } else {
            return !invitedProfile || invitedVenues.length === 0;
        }
    };

    handleClearInvitedProfile = () => {
        this.props.agentVenueStore.setInvitedProfile(undefined);
        this.props.agentVenueStore.setInvitedVenues([]);
    };

    handleValidate(value, type) {
        switch (type) {
            case "firstName":
                this.props.userInvitationStore.validateInvitedUserFirstName(value);
                break;
            case "lastName":
                this.props.userInvitationStore.validateInvitedUserLastName(value);
                break;
            case "email":
                this.props.userInvitationStore.validateInvitedUserEmail(value);
                break;;
        
            default:
                break;
        }
    }

    render() {
        const { inviteNewUser } = this.state;
        const { invitedUserFirstName, invitedUserLastName, invitedUserEmail, errors } = this.props.userInvitationStore;
        const { invitedProfile } = this.props.agentVenueStore;
        this.mapVenuesToRowData();

        return (
            <ModalContainer title={inviteNewUser ? "Invite New Agent" : "Invite Agent"} open={this.props.open} onClose={this.props.onClose}>
                <DialogContent>
                    <Grid container spacing={16}>
                        <Grid item xs={12} lg={6}>
                            {invitedProfile && !inviteNewUser ? (
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <Avatar
                                                alt={invitedProfile.fullName}
                                                src={image.getProfileImage(invitedProfile)}
                                                style={{
                                                    width: 40,
                                                    height: 40
                                                }}
                                            />
                                        }
                                        action={
                                            this.props.onProfile ? null :
                                            <IconButton onClick={this.handleClearInvitedProfile}>
                                                <ClearIcon />
                                            </IconButton>
                                        }
                                        title={this.props.agentVenueStore.invitedProfile.fullName}
                                    />
                                </Card>
                            ) : !invitedProfile && !inviteNewUser ? (
                                <Fragment>
                                    <InputLabel>Agent</InputLabel>
                                    <ProfileSearchBox
                                        placeholder="Search Agent"
                                        id="invite-act-name"
                                        handleSearch={value => this.props.profileStore.searchAgencyProfile(value)}
                                        handleChange={ this.handleSelect }
                                        handleEmpty={this.handleNoAgentFound}
                                        menuStyle={{
                                            position: "absolute",
                                            maxHeight: "300px",
                                            overflowY: "auto",
                                            width: "100%"
                                        }}
                                    />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Grid container spacing={8}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Agent First Name"
                                                value={invitedUserFirstName}
                                                fullWidth
                                                onBlur={e => this.handleValidate(e.target.value, "firstName")}
                                                onChange={e => this.props.userInvitationStore.setInvitedUserFirstName(e.target.value)}
                                                error={errors && errors["firstName"] ? true : false}
                                                helperText={errors && errors["firstName"] ? errors["firstName"] : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Agent Last Name"
                                                value={invitedUserLastName}
                                                fullWidth
                                                onBlur={e => this.handleValidate(e.target.value, "lastName")}
                                                onChange={e => this.props.userInvitationStore.setInvitedUserLastName(e.target.value)}
                                                error={errors && errors["lastName"] ? true : false}
                                                helperText={errors && errors["lastName"] ? errors["lastName"] : ""}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Agent Email"
                                                value={invitedUserEmail}
                                                fullWidth
                                                onBlur={e => this.handleValidate(e.target.value, "email")}
                                                onChange={e => this.props.userInvitationStore.setInvitedUserEmail(e.target.value)}
                                                error={errors && errors["email"]}
                                                helperText={errors && errors["email"] ? errors["email"] : ""}
                                            />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )}
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputLabel>Venues</InputLabel>
                            <ResponsiveTable
                                selectable={true}
                                rows={this.venueRows}
                                columns={this.venueColumns}
                                getCheckedRows={this.getCheckedVenues}
                                pagination={this.venueRows.length > 5}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>Booking Days</InputLabel>
                            {this.renderDaySwitch()}
                            <Typography variant="caption">Unselected days will restrict agent to create gig on those days.</Typography>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <InputLabel>Invitation Message</InputLabel>
                            <RichTextEditor
                                label="Invitation Message"
                                value={this.getInvitationMessage()}
                                onChange={this.handleInvitationMessageChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose}>Close</Button>
                    <Button id="invite-agent-submit" color="primary" onClick={this.handleInvite} disabled={this.isInviteButtonDisabled()}>
                        Invite
                    </Button>
                </DialogActions>
            </ModalContainer>
        );
    }
}

export default StaffAgentInvitationModal;
