import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

@inject("songListStore")
@observer
class AdminTableHeader extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };
    render() {
        const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

        return (
            <TableHead>
                <TableRow>

                    <TableCell className="th-songlist" key="email" padding="default" sortDirection={orderBy === "email" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "email"} direction={order} onClick={this.createSortHandler("email")}>
                                <Typography variant="subheading" className="head-title">
                                    Email
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="firstName" padding="default" sortDirection={orderBy === "firstName" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "firstName"} direction={order} onClick={this.createSortHandler("firstName")}>
                                <Typography variant="subheading" className="head-title">
                                    FirstName
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="lastName" padding="default" sortDirection={orderBy === "lastName" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "lastName"} direction={order} onClick={this.createSortHandler("lastName")}>
                                <Typography variant="subheading" className="head-title">
                                    LastName
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="status" padding="default" sortDirection={orderBy === "status" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "status"} direction={order} onClick={this.createSortHandler("status")}>
                                <Typography variant="subheading" className="head-title">
                                    Status
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="type" padding="default" sortDirection={orderBy === "type" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "type"} direction={order} onClick={this.createSortHandler("type")}>
                                <Typography variant="subheading" className="head-title">
                                    Type
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="musician" padding="default" sortDirection={orderBy === "musician" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "musician"} direction={order} onClick={this.createSortHandler("musician")}>
                                <Typography variant="subheading" className="head-title">
                                    Musician
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="agent" padding="default" sortDirection={orderBy === "agent" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "agent"} direction={order} onClick={this.createSortHandler("agent")}>
                                <Typography variant="subheading" className="head-title">
                                    Agent
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="venue" padding="default" sortDirection={orderBy === "venue" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "venue"} direction={order} onClick={this.createSortHandler("venue")}>
                                <Typography variant="subheading" className="head-title">
                                    Venue
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    <TableCell className="th-songlist" key="venue" padding="default" sortDirection={orderBy === "venue" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "venue"} direction={order} onClick={this.createSortHandler("venue")}>
                                <Typography variant="subheading" className="head-title">
                                Parties & Events
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>

                    {/* <TableCell className="th-songlist" key="subscription" padding="default" sortDirection={orderBy === "subscription" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "subscription"} direction={order} onClick={this.createSortHandler("subscription")}>
                                <Typography variant="subheading" className="head-title">
                                Subscription
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                    
                    <TableCell className="th-songlist" key="currentPeriod" padding="default" sortDirection={orderBy === "currentPeriod" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "currentPeriod"} direction={order} onClick={this.createSortHandler("currentPeriod")}>
                                <Typography variant="subheading" className="head-title">
                                Current Period
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell>
                    
                    <TableCell className="th-songlist" key="access" padding="default" sortDirection={orderBy === "access" ? order : false}>
                        <Tooltip title="Sort" placement="bottom-start" enterDelay={300}>
                            <TableSortLabel active={orderBy === "access"} direction={order} onClick={this.createSortHandler("access")}>
                                <Typography variant="subheading" className="head-title">
                                Access
                                </Typography>
                            </TableSortLabel>
                        </Tooltip>
                    </TableCell> */}

                    <TableCell className="th-songlist" key="mediaUrl" style={{ width: "10%" }}>
                        <Typography variant="subheading" className="head-title">
                            Action
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }
}

AdminTableHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired
};

export default AdminTableHeader;
