import { observable, action, runInAction } from "mobx";
import agent from "../agent";
import _ from "lodash";

import templateStore from "./templateStore";
import { ProfileType } from "../types/enum";
import { Promise } from "bluebird";
import { Storage } from "aws-amplify";
import CacheHelper from "../helper/cache";
import userStore from "./userStore";
import billingStore from "./billingStore";

const API_ROOT = process.env.REACT_APP_MAIN_SERVICE_URL;

class ProfileStore {
    @observable role = undefined;

    @observable profile = undefined;
    @observable currentProfile = undefined;
    @observable profiles = [];
    @observable currentProfileType = 0;
    @observable isLoadingProfile = false;
    @observable profileSearchResult = [];
    @observable actList = [];
    @observable actListTotal = 0;
    @observable markInvoiceAsSent = false

    @observable xeroIncludePDF = false;
    @observable xeroSettingApproved = false;
    
    @observable actSearchResult = [];
    @observable musicianSearchResult = [];
    @observable venueSearchResult = [];
    @observable agentSearchResult = [];
    @observable productionSearchResult = [];
    @observable agentActList = [];
    @observable termCondition = undefined;
    @observable clientContract = undefined;
    @observable notes = undefined;
    @observable requirements = undefined;
    @observable isSoloAct = false;

    @observable venueNameTitle = "Create Venue";
    @observable isCreateVenue = false;


    @action
    setVenueNameTitle(venueNameTitle) {
        this.venueNameTitle = venueNameTitle;
    }

    @action
    setStatusCreateVenue(status) {
        this.isCreateVenue = status
    }

    @action
    setMarkInvoiceAsSent(value) {
        this.markInvoiceAsSent = value
    }

    @action
    getCurrentProfile() {
        if (typeof this.currentProfile === "undefined") {
            return CacheHelper.getCurrentProfile();
        }
        else {
            return this.currentProfile;
        }
    };

    @action
    setCurrentProfile(profile) {
        this.currentProfile = profile;
        CacheHelper.setCurrentProfile(profile)
    }

    @action
    setCheckboxInvoice(profileId) {
        return agent.Profile.setCheckboxInvoice(profileId)
            .then(
                action(async profile => {
                    return profile;
                })
            )
            .catch(
                action(() => {
                    return null
                })
            );
    }

    @action
    resetAgentActList() {
        this.agentActList = [];
    }

    @action
    reset() {
        this.currentProfile = undefined;
        this.profile = undefined;
        this.currentProfileType = 0;
    }

    mapAdditionalProfileData = profile => {
        if (profile) {
            if (profile.profileImage && typeof profile.profileImage.url === "undefined") {
                CacheHelper.getImgLink(profile.profileImage.url200).then(
                    action(result => {
                        profile.profileImage.url200 = result;
                    }));
                CacheHelper.getImgLink(profile.profileImage.urlOriginal).then(
                    action(result => {
                        profile.profileImage.url = result;
                    }));
                CacheHelper.getImgLink(profile.profileImage.url400).then(
                    action(result => {
                        profile.profileImage.url400 = result;
                    }));
            }
            if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                for (var j = 0; j < profile.backgroundImages.length; j++) {
                    if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                        CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal).then(
                            action(result => {
                                if (typeof profile.backgroundImages[j] !== "undefined") {
                                    profile.backgroundImages[j].url = result;
                                }
                            }));
                        CacheHelper.getImgLink(profile.backgroundImages[j].url960).then(
                            action(result => {
                                if (typeof profile.backgroundImages[j] !== "undefined") {
                                    profile.backgroundImages[j].url960 = result;
                                }
                            }));
                    }
                }
            } else {
                profile.backgroundImages = undefined;
            }
            profile.actName = profile?.actName;
            profile.profileName = profile?.profileName;

            profile.fullName = templateStore.getFullName(profile);
            profile.typeString = templateStore.getTypeString(profile.type);
            profile.labelType = templateStore.getLabelType(profile.type);

            if (profile.labelType === "Agent" && profile.isConsultant) {

                profile.labelType = "consultant";
                profile.isConsultant = true;
            } else if (
                profile.labelType === "Agent" &&
                !profile.isConsultant &&
                profile.myAgencyConsultant &&
                profile.myAgencyConsultant.length > 0
            ) {

                profile.labelType = "agency";
                profile.isAgency = true;
            }

            if (profile.currency === "GBP") {
                profile.currencySymbol = "£";
            } else {
                profile.currencySymbol = "$";
            }
        }
    };
    @action
    toggleXeroSyncPDF(id){
        return agent.Profile.toggleXeroSyncPDF(id).then(action(
            result => {
                this.xeroIncludePDF = result.xeroIncludePDF
                return result;
            }
        ));
    }

    @action
    toggleXeroInvoiceSent(id){
        return agent.Profile.toggleXeroMarkAsSent(id).then(action(
            result => {
            //    / this.xeroSettingApproved = result.xeroSettingApproved
                return result;
            }
        ));
    }
    @action
    toggleXeroSettingApproved(id){
        return agent.Profile.toggleXeroSettingApproved(id).then(action(
            result => {
            //    / this.xeroSettingApproved = result.xeroSettingApproved
                return result;
            }
        ));
    }
    
    mapAdditionalProfileDataNoImage = profile => {
        if (profile) {
            profile.actName = profile?.actName;
            profile.profileName = profile?.profileName;

            profile.fullName = templateStore.getFullName(profile);
            profile.typeString = templateStore.getTypeString(profile.type);
            profile.labelType = templateStore.getLabelType(profile.type);

            if (profile.labelType === "Agent" && profile.isConsultant) {

                profile.labelType = "consultant";
                profile.isConsultant = true;
            } else if (
                profile.labelType === "Agent" &&
                !profile.isConsultant &&
                profile.myAgencyConsultant &&
                profile.myAgencyConsultant.length > 0
            ) {

                profile.labelType = "agency";
                profile.isAgency = true;
            }

            if (profile.currency === "GBP") {
                profile.currencySymbol = "£";
            } else {
                profile.currencySymbol = "$";
            }
        }
    };
    
    mapAdditionalProfileDataAsync = async profile => {
        if (profile) {
            if (profile.profileImage && typeof profile.profileImage.url === "undefined") {
                await CacheHelper.getImgLink(profile.profileImage.url200).then(
                    action(result => {
                        profile.profileImage.url200 = result;
                    }));
                await CacheHelper.getImgLink(profile.profileImage.urlOriginal).then(
                    action(result => {
                        profile.profileImage.url = result;
                    }));
                await CacheHelper.getImgLink(profile.profileImage.url400).then(
                    action(result => {
                        profile.profileImage.url400 = result;
                    }));
            }
            if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                for (var j = 0; j < profile.backgroundImages.length; j++) {
                    if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                        await CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal).then(
                            action(result => {
                                if (typeof profile.backgroundImages[j] !== "undefined") {
                                    profile.backgroundImages[j].url = result;
                                }
                            }));
                        await CacheHelper.getImgLink(profile.backgroundImages[j].url960).then(
                            action(result => {
                                if (typeof profile.backgroundImages[j] !== "undefined") {
                                    profile.backgroundImages[j].url960 = result;
                                }
                            }));
                        await CacheHelper.getImgLink(profile.backgroundImages[j].url1920).then(
                            action(result => {
                                if (typeof profile.backgroundImages[j] !== "undefined") {
                                    profile.backgroundImages[j].url1920 = result;
                                }
                            }));
                    }
                }
            } else {
                profile.backgroundImages = undefined;
            }
            profile.actName = profile?.actName;
            profile.profileName = profile?.profileName;

            profile.fullName = templateStore.getFullName(profile);
            profile.typeString = templateStore.getTypeString(profile.type);
            profile.labelType = templateStore.getLabelType(profile.type);

            if (profile.labelType === "Agent" && profile.isConsultant) {

                profile.labelType = "consultant";
                profile.isConsultant = true;
            } else if (
                profile.labelType === "Agent" &&
                !profile.isConsultant &&
                profile.myAgencyConsultant &&
                profile.myAgencyConsultant.length > 0
            ) {

                profile.labelType = "agency";
                profile.isAgency = true;
            }

            if (profile.currency === "GBP") {
                profile.currencySymbol = "£";
            } else {
                profile.currencySymbol = "$";
            }
        }
    };


    @action
    getProfileRole() {
        if (this.profile) {
            switch (this.profile.type) {
                case 1:
                    return "musician";
                case 2:
                    return "agent";
                case 3:
                    return "venue";
                case 6:
                    return "production";
                case 7:
                    return "dj";
                default:
                    return "";
            }
        }
        return "";
    }

    mapAdditionalProfileImageAndBackgroundImages = async (profile) => {
        if (profile) {
            if (profile.profileImage && typeof profile.profileImage.url === "undefined") {
                let updatedProfileImage = {}
                updatedProfileImage.url200 = await CacheHelper.getImgLink(profile.profileImage.url200)
                updatedProfileImage.url = await CacheHelper.getImgLink(profile.profileImage.urlOriginal)
                updatedProfileImage.url400 = await CacheHelper.getImgLink(profile.profileImage.url400)
                runInAction(() => {
                    profile.profileImage = updatedProfileImage;
                })
            }
            if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                let updatedBackgroundImage = []
                for (const image of profile.backgroundImages) {
                    let newBackgroundImage = {}
                    if (typeof image !== "undefined" && typeof image.url === "undefined") {
                        newBackgroundImage.url = await CacheHelper.getImgLink(image.urlOriginal);
                        newBackgroundImage.url960 = await CacheHelper.getImgLink(image.urlOriginal);
                        updatedBackgroundImage.push(newBackgroundImage);
                    }
                }
                runInAction(() => {
                    profile.backgroundImages = updatedBackgroundImage;
                })
            }
        }
    }

    @action
    mapAdditionalProfileNames = (profile) => {
        if (profile) {
            profile.actName = profile?.actName;
            profile.profileName = profile?.profileName;

            profile.fullName = templateStore.getFullName(profile);
        }
    }

    @action
    loadProfiles() {
        this.profile = undefined;
        //this.currentProfile = undefined;
        return agent.Profile.loadProfiles()
            .then(
                action(profiles => {

                    this.profiles = !profiles ? [] : profiles;

                    let localStorage = window.localStorage.getItem("currentProfileType");
                    localStorage = localStorage ? localStorage * 1 : 0;
                    if (this.currentProfileType === 0 && localStorage) {
                        this.currentProfileType = localStorage;
                    }

                    if (profiles && profiles.length > 0) {
                        var profileFounded = false;
                        this.profiles.forEach(profile => {
                            this.mapAdditionalProfileData(profile);

                            if (
                                this.currentProfileType === 0 &&
                                !localStorage &&
                                (profile.type !== ProfileType.Act.ordinal || profile.type !== ProfileType.Venue.ordinal)
                            ) {
                                this.currentProfileType = profile.type;
                                window.localStorage.setItem("currentProfileType", profile.type);
                            }

                            if (profile.type === this.currentProfileType) {
                                this.profile = profile;
                                this.currentProfile = profile;
                                CacheHelper.setCurrentProfile(profile);
                                profileFounded = true;
                            }
                        });

                        if (!profileFounded) {
                            this.profile = this.profiles[0];
                            this.currentProfile = this.profiles[0];
                            CacheHelper.setCurrentProfile(this.profiles[0]);
                            this.currentProfileType = this.profile.type;
                            window.localStorage.setItem("currentProfileType", this.profile.type);
                        }
                    }
                    return this.profiles;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingProfile = false;
                })
            );
    }

    @action
    loadProfilesAfterLogin() {
        this.profile = undefined;
        //this.currentProfile = undefined;
        return agent.Profile.loadProfiles()
            .then(
                action(async profiles => {

                    this.profiles = !profiles ? [] : profiles;

                    let localStorage = window.localStorage.getItem("currentProfileType");
                    localStorage = localStorage ? localStorage * 1 : 0;
                    if (this.currentProfileType === 0 && localStorage) {
                        this.currentProfileType = localStorage;
                    }

                    if (profiles && profiles.length > 0) {
                        var profileFounded = false;
                        this.profiles.forEach(profile => {
                            this.mapAdditionalProfileData(profile);

                            if (
                                this.currentProfileType === 0 &&
                                !localStorage &&
                                (profile.type !== ProfileType.Act.ordinal || profile.type !== ProfileType.Venue.ordinal)
                            ) {
                                this.currentProfileType = profile.type;
                                window.localStorage.setItem("currentProfileType", profile.type);
                            }

                            if (profile.type === this.currentProfileType) {
                                this.profile = profile;
                                this.currentProfile = profile;
                                CacheHelper.setCurrentProfile(profile);
                                profileFounded = true;
                            }
                        });

                        if (!profileFounded) {
                            this.profile = this.profiles[0];
                            this.currentProfile = this.profiles[0];
                            CacheHelper.setCurrentProfile(this.profiles[0]);
                            this.currentProfileType = this.profile.type;
                            window.localStorage.setItem("currentProfileType", this.profile.type);
                        }
                    }
                    await billingStore.reloadAccessSynch();
                    userStore.saveSubscribeLogin()
                    return this.profiles;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingProfile = false;
                })
            );
    }

    @action
    hasProfile(profileType) {
        var found = false;
        if (this.profiles) {
            this.profiles.forEach(profile => {
                if (profile.type === profileType) {
                    found = true;
                }
            });
        }
        return found;
    }

    @action
    getProfile(profileId) {
        this.isLoadingProfile = true;
        return agent.Profile.getById(profileId)
            .then(
                action(async profile => {
                    this.profile = profile;
                    await this.mapAdditionalProfileData(this.profile);

                    return this.profile;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingProfile = false;
                })
            );
    }

    @action
    getByIdSimple(profileId) {
        this.isLoadingProfile = true;
        return agent.Profile.getByIdSimple(profileId)
            .then(
                action(async profile => {
                    return profile
                })
            )
    }
    
    @action
    getInstrumentNameByInstrumentProfile(instruments) {
        var instrumentResult = []
        return agent.Instrument.getAll().then(
            action(result => {

                instruments.forEach(element => {

                    var instrumentName = result.find(x => x.id === element.instrumentId).name
                    instrumentResult.push({
                        text: instrumentName,
                        link: "#"
                    })

                });

                return instrumentResult
            }))
    }

    @action
    getProfileByIdSimple(profileId){
        return agent.Profile.get(profileId)
            .then(
                action(async profile => {
                    return profile;
                })
            )
            .catch(
                action(() => {
                    return null
                })
            );
    }
    @action
    getProfileById(profileId) {
        this.isLoadingProfile = true;
        return agent.Profile.getById(profileId)
            .then(
                action(async profile => {
                    await this.mapAdditionalProfileData(profile);
                    return profile;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingProfile = false;
                })
            );
    }

    @action
    loadProfile(userId, profileTypeId, hideLoadingScreen) {
        this.isLoadingProfile = true;
        if (!hideLoadingScreen) {
            templateStore.showLoadingScreen();
        }

        return agent.Profile.getByUserIdAndType(userId, profileTypeId)
            .then(
                action(async profile => {
                    this.profile = profile;
                    await this.mapAdditionalProfileData(this.profile);

                    return this.profile;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingProfile = false;
                    templateStore.hideLoadingScreen();
                })
            );
    }

    @action
    getUrlImage = async (key) => {
        const url = await Storage.get(`${key}`, { expires: 604800 })
        return url
    }

    @action
    mapSearchProfiles = async (profiles) => {
        await Promise.all(profiles.map(async profile => {
            if (profile.profileImage && profile.profileImage !== null && typeof profile.profileImage.url === "undefined") {
                profile.profileImage.url200 = await CacheHelper.getImgLink(profile.profileImage.url200);
                profile.profileImage.url = await CacheHelper.getImgLink(profile.profileImage.urlOriginal);
                profile.profileImage.url400 = await CacheHelper.getImgLink(profile.profileImage.url400);
            }
            if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                for (var j = 0; j < profile.backgroundImages.length; j++) {
                    if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                        profile.backgroundImages[j].url = await CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal);
                        profile.backgroundImages[j].url960 = await CacheHelper.getImgLink(profile.backgroundImages[j].url960);
                    }
                }
            } else {
                profile.backgroundImages = undefined;
            }

            profile.actName = profile?.actName;
            profile.profileName = profile?.profileName;

            profile.fullName = templateStore.getFullName(profile);
            profile.typeString = templateStore.getTypeString(profile.type);
            profile.labelType = templateStore.getLabelType(profile.type);

            if (profile.labelType === "Agent" && profile.isConsultant) {

                profile.labelType = "consultant";
                profile.isConsultant = true;
            } else if (
                profile.labelType === "Agent" &&
                !profile.isConsultant &&
                profile.myAgencyConsultant &&
                profile.myAgencyConsultant.length > 0
            ) {

                profile.labelType = "agency";
                profile.isAgency = true;
            }

            if (profile.currency === "GBP") {
                profile.currencySymbol = "£";
            } else {
                profile.currencySymbol = "$";
            }
        }))
        return profiles;
    }

    @action
    mapProfiles = async (profiles) => {
        for (var i = 0; i < profiles.length; i++) {
            var profile = profiles[i];
            if (profile.profileImage && profile.profileImage !== null && typeof profile.profileImage.url === "undefined") {
                profile.profileImage.url200 = await CacheHelper.getImgLink(profile.profileImage.url200);
                profile.profileImage.url = await CacheHelper.getImgLink(profile.profileImage.urlOriginal);
                profile.profileImage.url400 = await CacheHelper.getImgLink(profile.profileImage.url400);
            }
            if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                for (var j = 0; j < profile.backgroundImages.length; j++) {
                    if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                        profile.backgroundImages[j].url = await CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal);
                        profile.backgroundImages[j].url960 = await CacheHelper.getImgLink(profile.backgroundImages[j].url960);
                    }
                }
            } else {
                profile.backgroundImages = undefined;
            }
            this.mapAdditionalProfileData(profile);
        }
        return profiles;
    }
    @action
    setXeroSettingApproved(value){
        this.xeroSettingApproved = value
    }
    @action
    setIncludePDF(value){
        this.xeroIncludePDF = value
    }
    @action
    getUserTotalActList(profileId) {
        this.isLoadingProfile = true;
        // templateStore.showLoadingScreen();
        let localStorage = window.localStorage.getItem('actsTotal');

        if (!profileId) profileId = this.getCurrentProfile().id;
        if (localStorage) {
            this.actListTotal = localStorage;
            templateStore.hideLoadingScreen();
        } else {
            return agent.Profile.getUserTotalActListByProfileId(profileId)
                .then(action(response => {
                    window.localStorage.setItem('actsTotal', response);
                    this.actListTotal = response;

                }))
                .finally(
                    action(async () => {
                        this.isLoadingProfile = false;
                        templateStore.hideLoadingScreen();
                    })
                )
        }

    }

    @action
    getUserActList(profileId) {
        this.isLoadingProfile = true;
        if (!profileId) profileId = this.getCurrentProfile().id;
        return agent.Profile.getUserActListByProfileId(profileId)
            .then(async response => {
                var mappedPending = [];
                for (var i = 0; i < response.length; i++) {
                    var data = response[i];
                    await this.mapAdditionalProfileDataAsync(data);
                    mappedPending.push(data)
                }
                runInAction(() => {
                    this.actList = mappedPending;
                })
                return this.actList;
            }
            )
            .finally(
                action(async () => {

                    this.isLoadingProfile = false;
                    templateStore.hideLoadingScreen();
                })
            );
    }

    @action
    setFavouriteOnSearch(id, value){
            this.actList = this.actList.filter(x => x.id !== id)
    }

    @action
    getPrivateHireFavouriteActList(profileId) {
        this.isLoadingProfile = true;
        if (!profileId) profileId = this.getCurrentProfile().id;
        return agent.UserFavourite.getUserFavouriteActs().then(async response => {
                var mappedPending = [];
                for (var i = 0; i < response.length; i++) {
                    var data = response[i].favouriteProfile;
                    await this.mapAdditionalProfileDataAsync(data);
                    mappedPending.push(data)
                }
                runInAction(() => {
                    this.actList = mappedPending;
                })
                return this.actList;
            }
            )
            .finally(
                action(async () => {

                    this.isLoadingProfile = false;
                    templateStore.hideLoadingScreen();
                })
            );
    }

    @action
    getAgentActList(profileId) {
        this.isLoadingProfile = true;
        if (!profileId) profileId = this.getCurrentProfile().id;
        return agent.Profile.getAgentMyActs(profileId)
            .then(async response => {
                var mappedPending = [];
                for (var i = 0; i < response.length; i++) {
                    var data = response[i];
                    await this.mapAdditionalProfileDataAsync(data);
                    mappedPending.push(data)
                }
                runInAction(() => {
                    this.actList = mappedPending;
                })
                return this.actList;
            }
            )
            .finally(
                action(async () => {
                    this.isLoadingProfile = false;
                    templateStore.hideLoadingScreen();
                })
            );
    }

    @action
    duplicateProfile(profileId) {
        this.isLoadingProfile = true;
        return agent.ActDuplicateProfile.duplicate(profileId)
            .then(
                action(async profile => {
                    return profile;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingProfile = false;
                })
            );
    }

    @action
    loadAgentActList() {
        var self = this;
        return agent.Profile.getUserActListByProfileId(this.getCurrentProfile().id)
            .then(
                action(response => {
                    self.agentActList = response;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
            });
    }

    @action
    deleteAgentActRoster(actRosterId) {
        return agent.ProfileRelationship.delete(actRosterId)
            .then(
                action(response => {
                    _.remove(this.actList, ["id", response.id]);
                    return response;
                })
            )
            .finally(() => {
                templateStore.hideLoadingScreen();
            });
    }

    @action
    searchMusician(name) {
        return agent.Profile.searchMusician(name).then(
            action(async response => {
                if (response) {
                    await this.mapProfiles(response);
                    this.profileSearchResult = response;
                    // this.profileSearchResult = await Promise.all(_.map(response, async data => {
                    //     await this.mapAdditionalProfileData(data);
                    //     return data;
                    // }));
                } else {
                    this.profileSearchResult = [];
                }
                return this.profileSearchResult;
            })
        );
    }

    @action
    searchConsultant(name) {
        return agent.Profile.searchConsultant(name).then(
            action(async response => {
                if (response) {
                    await this.mapProfiles(response);
                    this.profileSearchResult = response;

                } else {
                    this.profileSearchResult = [];
                }
                return this.profileSearchResult;
            })
        );
    }

    @action
    searchAllProfiles(name) {
        return agent.Profile.getSearchAllProfiles(name).then(
            action(async response => {
                let result = [];
                if (response) {
                    for (let i = 0; i < response.length; i++) {
                        let tempProfile = {
                            ...response[i].profile,
                            profileImage: response[i].profileImage
                        }
                        await this.mapAdditionalProfileDataAsync(tempProfile)
                        tempProfile.fullName = response[i].name + (response[i].type == ProfileType.Staff.ordinal ? ` (${response[i]?.venue?.profileName} - ${response[i].staffTitle})` : "")
                        if (this.currentProfile?.id !== tempProfile.id) result.push(tempProfile)
                    }
                }
                this.profileSearchResult = result
                return this.profileSearchResult
            })
        )
    }

    @action
    logProfileViews(id) {
        agent.ProfileViews.insertProfileViews(id)
    }
    @action
    searchProfile(name) {
        return agent.Profile.searchProfile(name).then(
            action(response => {
                if (response) {
                    this.profileSearchResult = _.map(response, data => {
                        return _.map(async (data, profile) => {
                            await this.mapAdditionalProfileData(profile);
                            return profile;
                        });
                    });
                } else {
                    this.profileSearchResult = [];
                }
                this.actSearchResult = this.profileSearchResult[0];
                this.musicianSearchResult = this.profileSearchResult[1];
                this.venueSearchResult = this.profileSearchResult[2];
                this.agentSearchResult = this.profileSearchResult[3];
                this.productionSearchResult = this.profileSearchResult[4];
                return this.profileSearchResult;
            })
        );
    }

    @action
    searchAct(name) {
        return agent.Profile.searchAct(name).then(
            action(async response => {
                if (response) {
                    await this.mapProfiles(response);
                    this.profileSearchResult = response;
                } else {
                    this.profileSearchResult = [];
                }

                return this.profileSearchResult;
            })
        );
    }

    @action
    searchOtherAct(name) {
        return agent.Profile.searchOtherAct(name).then(
            action(response => {
                if (response) {
                    this.profileSearchResult = _.map(async (response, data) => {
                        await this.mapAdditionalProfileData(data);
                        return data;
                    });
                } else {
                    this.profileSearchResult = [];
                }

                return this.profileSearchResult;
            })
        );
    }

    @action
    searchVenueProfile(query) {
        return agent.Profile.searchVenueProfile(query).then(
            action(async response => {
                if (response) {
                    await this.mapProfiles(response);
                    this.profileSearchResult = response;
                } else {
                    this.profileSearchResult = [];
                }
                return this.profileSearchResult;
            })
        );
    }

    @action
    searchProduction(name) {
        return agent.Profile.searchProduction(name).then(
            action(response => {
                if (response) {
                    this.profileSearchResult = _.map(async (response, data) => {
                        await this.mapAdditionalProfileData(data);
                        return data;
                    });
                } else {
                    this.profileSearchResult = [];
                }
                return this.profileSearchResult;
            })
        );
    }

    @action
    searchAgentProfile(name) {
        return agent.Profile.searchAgent(name).then(
            action(async response => {
                if (response) {
                    for (var i = 0; i < response.length; i++) {
                        var data = response[i];
                        await this.mapProfiles(data);
                    }
                    this.profileSearchResult = response;
                    // this.profileSearchResult = _.map(async (response, data) => {
                    //     await this.mapAdditionalProfileData(data);
                    //     return data;
                    // });
                } else {
                    this.profileSearchResult = [];
                }
                return this.profileSearchResult;
            })
        );
    }
    @action
    searchMemberVenueProfile(name, existingMembers) {
        return agent.Profile.searchMemberVenueProfile(name).then(
            action(async response => {
                if (response) {
                    response = response.filter(function (item) {
                        return existingMembers.find(function (item2) {
                            return item.email == item2.email;
                        }) == undefined;
                    });
                    await this.mapProfiles(response);
                    this.profileSearchResult = response;
                    // this.profileSearchResult = _.map(async (response, data) => {
                    //     await this.mapAdditionalProfileData(data);
                    //     return data;
                    // });
                } else {
                    this.profileSearchResult = [];
                }
                return this.profileSearchResult;
            })
        );
    }

    @action
    disconnectGoogle(profileId) {
        if(this.currentProfile){
      
        return agent.GoogleCalendar.disconnectGoogle({profileId : profileId ? profileId : this.currentProfile.id})
            .then(
                action(result => {
                    this.currentProfile.activateGoogleCalendar = undefined;
                    this.currentProfile.googleRefreshToken = undefined;
            
                })
            )
            .finally(
                action(() => {
                    this.loadingCalendar = false;
                })
            );  
        }
    }
    @action
    searchAgencyProfile(name) {
        return agent.Profile.searchAgency(name).then(
            action(async response => {
                if (response) {
                    this.profileSearchResult = [];
                    for (var i = 0; i < response.length; i++) {
                        var data = response[i]
                        await this.mapAdditionalProfileDataAsync(data);
                        this.profileSearchResult.push(data);
                    }
                } else {
                    this.profileSearchResult = [];
                }
                return this.profileSearchResult;
            })
        );
    }

    @action
    getPublicProfileByVanityUrl(vanityUrl) {
        this.isLoadingProfile = true;
        return agent.Profile.getPublicProfileByVanityUrl(vanityUrl)
            .then(
                action(async profile => {
                    if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                        for (var j = 0; j < profile.backgroundImages.length; j++) {
                            if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                                profile.backgroundImages[j].url = await CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal);
                                profile.backgroundImages[j].url960 = await CacheHelper.getImgLink(profile.backgroundImages[j].url960);
                                profile.backgroundImages[j].url1920 = await CacheHelper.getImgLink(profile.backgroundImages[j].url1920);
                            }
                        }
                    } else {
                        profile.backgroundImages = undefined;
                    }
                    await this.mapAdditionalProfileDataAsync(profile);
                    return profile;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingProfile = false;
                })
            );
    }

    @action
    getPublicProfileById(id) {
        this.isLoadingProfile = true;
        return agent.Profile.getPublicProfileById(id)
            .then(
                action(async profile => {
                    if (profile.profileImage !== null && profile.profileImage && typeof profile.profileImage.url === "undefined") {
                        profile.profileImage.url200 = await CacheHelper.getImgLink(profile.profileImage.url200);
                        profile.profileImage.url = await CacheHelper.getImgLink(profile.profileImage.urlOriginal);
                        profile.profileImage.url400 = await CacheHelper.getImgLink(profile.profileImage.url400);
                    }
                    if (profile.backgroundImages && typeof profile.backgroundImages !== "undefined" && profile.backgroundImages.length > 0) {
                        for (var j = 0; j < profile.backgroundImages.length; j++) {
                            if (typeof profile.backgroundImages[j] !== "undefined" && typeof profile.backgroundImages[j].url === "undefined") {
                                profile.backgroundImages[j].url = await CacheHelper.getImgLink(profile.backgroundImages[j].urlOriginal);
                                profile.backgroundImages[j].url960 = await CacheHelper.getImgLink(profile.backgroundImages[j].url960);
                                profile.backgroundImages[j].url1920 = await CacheHelper.getImgLink(profile.backgroundImages[j].url1920);
                            }
                        }
                    } else {
                        profile.backgroundImages = undefined;
                    }
                    this.mapAdditionalProfileData(profile);

                    return profile;
                })
            )
            .finally(
                action(() => {
                    this.isLoadingProfile = false;
                })
            );
    }

    @action
    setProfileSearchResult(searchResult) {
        this.profileSearchResult = searchResult;
    }

    @action
    setSoloAct(isSoloAct) {
        this.isSoloAct = isSoloAct;
    }

    @action
    deleteProfile(id) {
        return agent.Profile.delete(id).then(
            action(response => {
                _.remove(this.actList, ["id", id]);
                return response;
            })
        );
    }

    @action
    createSoloAct() {
        return agent.Profile.createSoloAct().then(
            action(profile => {
                this.isSoloAct = true;
                return profile;
            })
        );
    }

    @action
    setProfileType(type) {
        if (this.currentProfileType !== type) {
            this.currentProfileType = type;
            window.localStorage.setItem("currentProfileType", type);

            if (this.profiles && this.profiles.length > 0) {
                for (let i = 0; i < this.profiles.length; i++) {
                    if (this.profiles[i].type === this.currentProfileType) {
                        this.profile = this.profiles[i];
                        this.currentProfile = this.profiles[i];
                        CacheHelper.setCurrentProfile(this.profiles[i]);
                        break;
                    }
                }
            }
        }
    }

    @action
    getCurrentProfileById(id) {
        if (this.profiles && this.profiles.length > 0) {
            var result = this.profiles.filter(x => x.id === id)
            return result.length > 0 ? result[0] : null;
        }
        return null;
    }

    @action
    hasAgencyRequest() {
        if (
            this.profile.myAgencies &&
            this.profile.myAgencies.length > 0 &&
            this.profile.myAgencies.filter(x => !x.deleted && !x.accepted).length > 0
        ) {
            return true;
        }
        return false;
    }

    @action
    updateCurrentProfileName(profile) {
        if (this.currentProfile && profile) {
            // this.currentProfile.firstName = profile.firstName || this.currentProfile.firstName;
            // this.currentProfile.lastName = profile.lastName || this.currentProfile.lastName;
            // set(this.currentProfile, { firstName: profile.firstName, lastName: profile.lastName });
            const newProfile = {
                ...this.currentProfile,
                firstName: profile.firstName,
                lastName: profile.lastName,
                fullName: `${profile.firstName} ${profile.lastName}`
            };
            this.currentProfile = newProfile;
            CacheHelper.setCurrentProfile(newProfile);
        }
    }

    getImageUrl(id) {
        return `${API_ROOT}/image/${id}`;
    }

    getBackgroundImageUrl(id) {
        return `${API_ROOT}/image/background/${id}`;
    }

    @action
    setFacebook(pageId, accessToken, pageName, pageCategory) {
        if (this.profile) {
            this.profile.facebookPageId = pageId;
            this.profile.facebookAccessToken = accessToken;
            this.profile.facebookPageName = pageName;
            this.profile.facebookPageCategory = pageCategory;
        }
    }

    @action
    resetInstagram() {
        if (this.profile) {
            this.profile.instagramProfile = null;
            this.profile.instagramAccessToken = null;
            this.profile.facebookInstagram = null;
            this.profile.instagramId = null;
            this.profile.instagramImageUrl = null;
            this.profile.facebookPageInstagramId = null;
        }
    }
    @action
    setInstagram(profile) {
        if (this.profile) {
            this.profile.instagramProfile = profile.instagramProfile;
            this.profile.instagramAccessToken = profile.instagramAccessToken;
            this.profile.facebookInstagramName = profile.facebookInstagramName;
            this.profile.instagramId = profile.instagramId;
            this.profile.instagramImageUrl = profile.instagramImageUrl;
            this.profile.facebookPageInstagramId = profile.facebookPageInstagramId;
            
        }
    }
    @action
    setTermCondition(value) {
        this.termCondition = value;
    }

    @action
    setClientContract(value) {
        this.clientContract = value;
    }

    @action
    setNotes(value) {
        this.notes = value;
    }

    @action
    setRequirements(value) {
        this.requirements = value;
    }

    @action
    loadTermCondition(id) {
        let profileId = id || this.profile.id;
        if (this.termCondition && this.termCondition.profileId === profileId) {
            return Promise.resolve(this.termCondition);
        }

        return agent.Profile.loadTermCondition(profileId).then(
            action(term => {
                this.termCondition = term;
                return this.termCondition;
            })
        );
    }

    
    @action
    getAllImageById(id) {
        return agent.Profile.getAllImageById(id).then(
            action(async result => {
                await Promise.all(result.backgroundImage.map(async item => {
                    if(item.urlOriginal){
                        item.url = await CacheHelper.getImgLink(item.urlOriginal);
                        item.url960 = await CacheHelper.getImgLink(item.urlOriginal);
                    }
                   
                }));
                var profileImage = result.profileImage
                if(profileImage?.urlOriginal){
                    profileImage.url200 = await CacheHelper.getImgLink(profileImage.url200)
                    profileImage.url = await CacheHelper.getImgLink(profileImage.urlOriginal)
                    profileImage.url400 = await CacheHelper.getImgLink(profileImage.url400)
                }

                result.profileImage = profileImage
                return result;
            })
        );
    }

    @action
    getBackgroundImage(id) {
        return agent.Profile.getBackgroundImageById(id).then(
            action(async result => {
                await Promise.all(result.map(async item => {
                    if(item.urlOriginal){
                        item.url = await CacheHelper.getImgLink(item.urlOriginal);
                        item.url960 = await CacheHelper.getImgLink(item.urlOriginal);
                    }
                   
                }));
                return result;
            })
        );
    }

    @action
    createTermCondition() {
        let terms = {
            profileId: this.profile.id,
            termCondition: this.termCondition,
            clientContract: this.clientContract,
            notes: this.notes,
            requirements: this.requirements
        };
        return agent.Profile.createTermCondition(terms).then(
            action(response => {
                this.termCondition = response.termCondition;
            })
        );
    }

    @action
    setCurrentProfileCurrency(currency) {
        if (this.currentProfile) {
            this.currentProfile.currency = currency;

            if (currency === "GBP") {
                this.currentProfile.currencySymbol = "£";
            } else {
                this.currentProfile.currencySymbol = "$";
            }
        }
    }

    @action
    setCurrentProfileTax(tax) {
        if (this.currentProfile) {
            this.currentProfile.tax = tax;
        }
    }

    @action
    setCurrentProfileImage(profileImage) {
        if (this.currentProfile) {
            this.currentProfile.profileImage = profileImage;
        }
    }

    @action
    setCurrentNames(profile) {
        if (this.currentProfile) {
            this.currentProfile.lastName = profile.lastName
            this.currentProfile.firstName = profile.firstName
            this.currentProfile.fullName = profile.fullName
            this.currentProfile.profileName = profile.profileName
        }
    }
    @action
    getProfileAct() {
        return agent.Profile.getProfileAct().then(
            action(async result => {
                
                return result;
            })
        );
    }

    @action
    canAgentInviteAct(actId) {
        const activeProfile = this.getCurrentProfile()
        if (activeProfile && activeProfile.type === ProfileType.Agent.ordinal) {
            return agent.Profile.canAgentInviteAct(activeProfile.id, actId).then(
                () => {
                    return true;
                },
                () => {
                    return false;
                }
            );
        } else {
            return false;
        }
    }

    @action
    canAgentInviteVenue(venueId) {
        if (this.currentProfile && this.currentProfile.type === ProfileType.Agent.ordinal) {
            return agent.Profile.canAgentInviteVenue(this.currentProfile.id, venueId).then(
                () => {
                    return true;
                },
                () => {
                    return false;
                }
            );
        } else {
            return false;
        }
    }
}

export default new ProfileStore();
