
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import Header from "../../components/Header";
import Loading from "../../components/loadingSpinner/Loading";
import MainContainer from "../../components/MainContainer";
import Sidebar from "../../components/Sidebar";
import "./SocialPostsDetail.css";
import Grid from "@material-ui/core/Grid";

import HubTimepicker3 from "../../components/timepicker/HubTimepicker3.js"
//"../../timepicker/HubTimepicker";
import Heading from "../../components/Heading";
import { DateTimePicker, DatePicker } from "material-ui-pickers";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Typography, TextField, InputLabel, Button, Select, MenuItem, Tooltip, Menu} from "@material-ui/core";
import Dropzone from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectGalleryModal from "../../components/modal/SelectGalleryModal.js"
import moment from "moment"
import CropImageModal from "../../components/modal/CropImageModal.js"
import TimePicker from 'react-time-picker';
import { Storage, Cache, Auth } from "aws-amplify";
import { ProfileType } from "../../types/enum.js";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

import SocialPostsDetailComponent from "./SocialPostsDetailComponent.js"

const FILE_MAXSIZE = 2097152;
const ACCEPTED_FILE = "image/*";
const facebookOn = "/facebook-on.png";
const facebookOff = "/facebook-off.png";
const instagramOn = "/instagram-on.png";
const instagramOff = "/instagram-off.png";
const uploadGallery = "/upload-gallery-image.png";
const uploadImage = "/upload-image.png";
const instagramImage = "/instagram-header-background.png";
const hotgiggityLive = "/hotgiggity-live-marker.png";


const defaultPreviewImage = "/default-preview-image.png";
const facebookFooterPreview = "/facebook-footer-preview.png";
const instagramFooterPreview = "/instagram-footer-preview.png";

const utcOffset = new Date().getTimezoneOffset();

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("templateStore","bookingRequestStore", "profileStore", "socialPromotionStore", "facebookStore")
@withRouter
@observer
class SocialPostsDetail extends Component {
    state = {
        file: null,
        switchUploadImage: false,
        switchSelectGallery: false,
        openGallery: false,
        image: null,
        feedPreview: 1,
        bookingData: undefined,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1 / 1,
        croppedImage: null,
        croppedAreaPixels: null,
        originalImage: null,
        openCropImage: false,
        something: "",
        includeAdd: false,
        facebookUserName: "Hotgiggity Live",
        instagramUserName: "HotgiggityLive",
        disableFacebook: false,
        disableInstagram: false,
        postOpen: false,
        isSchedule: 1,
        actionAnchor: [],
        firstLoad: true
    };
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentWillUnmount() {
    }


    loadGigs = () => {
        const { socialPost } = this.props.socialPromotionStore;
        const { currentProfile } = this.props.profileStore;
        const { match } = this.props;

        this.props.bookingRequestStore.showLoadingState();

        this.props.bookingRequestStore.load(match.params.bookingId,undefined).then(async (booking) => {
            booking.startDate = moment(booking.dateStart).subtract(parseFloat(booking.utcOffset) + this.offset,'minute');
            booking.endDate = moment(booking.dateStart).subtract(parseFloat(booking.utcOffset) + this.offset,'minute');
            
            // await Promise.all([
            //     this.props.socialPromotionStore.load(match.params.bookingId,profileId),
            //     this.props.profileStore.getAllImageById(booking.actId)
            // ]).then((res) => {
            //     var socialPostData = res[0]
            //     var result = res[1]
            //     this.props.bookingRequestStore.hideLoadingState();
            //     if(this.props.socialPromotionStore.socialPost && this.props.socialPromotionStore.socialPost?.id > 0){
            //     }else{
            //         let formattedVenueName = booking.venueName ? "#" + booking.venueName?.replace(/ /g, "") : booking.venue ? "#" + booking.venue?.profileName?.replace(/ /g, "") : "";
            //         let formattedActName = booking.actName ? "#" + booking.actName.replace(/ /g, "") : "";
            //         let formattedActName2 = booking.actName ? booking.actName : booking.act ? booking.act?.actName : ""
            //         let bookTableUrl = booking.venue && booking.venue.bookTableUrl ? booking.venue.bookTableUrl  : ""
                    
            //         var dateMoment = moment(new Date(booking.dateStart))
        
            //         let message = `Hi Folks. I have a gig coming up at ${formattedVenueName} on ${dateMoment.format('dddd')} the ${dateMoment.format("DD")} of ${dateMoment.format("MMMM")} from ${moment(
            //             booking.timeStart,
            //             "HH:mm:ss" 
            //         ).format("hh:mm A")} ${moment(
            //             booking.timeEnd,
            //             "HH:mm:ss" 
            //         ).format("hh:mm A")}.\n\nWould love to see you there! \n\n${formattedVenueName} ${formattedActName} #livegigs #supportlocal #livemusic`

            //         if(currentProfile?.type === ProfileType.Agent.ordinal){
            //             message = `Hi Folks. Catch ${formattedActName2} performing at ${formattedVenueName} on ${dateMoment.format('dddd')} the ${dateMoment.format("DD")} of ${dateMoment.format("MMMM")} from ${moment(
            //                 booking.timeStart,
            //                 "HH:mm:ss" 
            //             ).format("hh:mm A")} ${moment(
            //                 booking.timeEnd,
            //                 "HH:mm:ss" 
            //             ).format("hh:mm A")}. \n\nBook a table: ${bookTableUrl} \n\n#${formattedVenueName} ${formattedActName} #livegigs #supportlocal #livemusic`
            //         }
            //         if(currentProfile?.type === ProfileType.Staff.ordinal){
            //             message = `Hi Folks. Catch ${formattedActName2} performing here on ${formattedVenueName} on ${dateMoment.format('dddd')} the ${dateMoment.format("DD")} of ${dateMoment.format("MMMM")} from ${moment(
            //                 booking.timeStart,
            //                 "HH:mm:ss" 
            //             ).format("hh:mm A")} ${moment(
            //                 booking.timeEnd,
            //                 "HH:mm:ss" 
            //             ).format("hh:mm A")}. \n\nBook a table: ${bookTableUrl} \n\n${formattedVenueName} ${formattedActName}  #livegigs #supportlocal #livemusic`
            //         }
            
            //         this.props.socialPromotionStore.setImageSocialPost('postContent', message);
            //     }
            //     this.props.facebookStore.setRefreshPage(profileId).then((result) => {
            //         this.props.profileStore.loadProfiles();
            //         })
            //     var facebookUserName = ""
            //     if(currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.type === ProfileType.Agent.ordinal ){
            //         facebookUserName = socialPost.useProfileSetting ? currentProfile?.facebookPageName : actProfile?.facebookPageName
            //     }else if(currentProfile?.type === ProfileType.Staff.ordinal){
            //         facebookUserName = booking?.venue?.facebookPageName
            //     }
            //     var disableFacebook = false
            //     if(facebookUserName){
            //         this.props.socialPromotionStore.setImageSocialPost('switchFacebook', true);
            //         disableFacebook = false
            //     }else{
            //         this.props.socialPromotionStore.setImageSocialPost('switchFacebook', false);
            //         facebookUserName = "Hotgiggity Live"
            //         disableFacebook = true
            //     }
               
            //     var instagramUserName = ""
            //     if(currentProfile?.type === ProfileType.Musician.ordinal || currentProfile?.type === ProfileType.Agent.ordinal ){
            //         instagramUserName = socialPost.useProfileSetting ? currentProfile?.facebookInstagramName :  actProfile?.facebookInstagramName
            //     }else if(currentProfile?.type === ProfileType.Staff.ordinal){
            //         instagramUserName = booking?.venue?.facebookInstagramName
            //     }
            //     var disableInstagram = false
            //     if(instagramUserName){
            //         this.props.socialPromotionStore.setImageSocialPost('switchInstagram', true);
            //         disableInstagram = false
            //     }else{
            //         this.props.socialPromotionStore.setImageSocialPost('switchInstagram', false);
            //         instagramUserName = "hotgiggitylive"
            //         disableInstagram = true
                    
            //     }
            //     this.setState({ image: result, bookingData:booking, facebookUserName: facebookUserName, instagramUserName: instagramUserName, disableFacebook: disableFacebook,  disableInstagram: disableInstagram });
            // })

            this.setState({ bookingData:booking });
            window.scrollTo(0, 0);
        })
    };

    // loadSocialContent = () => {
    //     const { currentProfile } = this.props.profileStore;
    //     const { match } = this.props;
    //     var profileId = currentProfile?.id
    //     if(currentProfile?.type === ProfileType.Agent.ordinal){
    //         if(currentProfile.myAgencies && currentProfile.myAgencies.length){
    //             profileId = currentProfile.myAgencies[0].agentId
    //         }
    //     }else if(currentProfile?.type === ProfileType.Staff.ordinal){
    //         profileId = this.state.bookingData?.venueId
    //     }
    //     this.props.socialPromotionStore.load(match.params.bookingId,profileId).then((result) => {
    //        console.log(result)
    //     })
    // };

    componentDidMount = () => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.showLoadingState();
        this.loadGigs()
    };


     
    render() {
        const breadcrumb = [{ name: "Promote"}, { name: "Social Posts",link: `/socialposts`  }];
        let { isLoading: isLoadingGigs } = this.props.bookingRequestStore;
        let { socialPost,timeStartString, eventDate, showProfileSetting } = this.props.socialPromotionStore;
        let { bookingData } = this.state;
        const { currentProfile } = this.props.profileStore;
        const { match } = this.props;
        var relatedProfile = currentProfile

        if(currentProfile?.type === ProfileType.Musician.ordinal){
            if(bookingData?.act?.actAdminId === currentProfile?.id){
                relatedProfile = bookingData?.act
            }
        }
        else if(currentProfile?.type === ProfileType.Agent.ordinal){
            if(currentProfile.myAgencies && currentProfile.myAgencies.length){
                relatedProfile = currentProfile.myAgencies[0].agent
            }
        }else if(currentProfile?.type === ProfileType.Staff.ordinal){
            relatedProfile = this.state.bookingData?.venue
        }
        let {
            scheduleTime,
            gigImage,
            gigGalleryImage,
            isProfile,
            postContent,
            switchInstagram,
            switchFacebook,
            id,
            useProfileSetting
        } = socialPost;
        return (
            <div>
                <Header />
                <Sidebar />
                <MainContainer>
                    <Breadcrumb links={breadcrumb} />
                    <Heading title={`${this.props.socialPromotionStore.socialPost?.id > 0 ? "Edit" : "Create"} Social Post : ${bookingData?.title} - ${moment(bookingData?.dateStart).format("DD-MM-YYYY")} - ${bookingData?.timeStart.slice(0, -3)}`}>
                    </Heading>
                    <SocialPostsDetailComponent bookingId={match.params.bookingId} notFromBooking={true}/>
                </MainContainer>
                {/* <SelectGalleryModal
                    open={this.state.openGallery}
                    title="Select 1 Image"
                    backgroundImage={this.state.image?.backgroundImage && this.state.image?.backgroundImage?.length ? this.state.image.backgroundImage : []}
                    profileImage= {this.state.image?.profileImage ? [this.state.image.profileImage] : []}
                    // message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                    closeHandler={this.handleCloseGallery}
                    confirmationHandler={this.handleSelectGalleryImage}
                    // declineHandler={this.handleCloseDeclineModal}
                    confirmationLabel="Confirm"
                    declineLabel="Cancel"
                    maxWidth="lg"
                />

                <CropImageModal
                    open={this.state.openCropImage}
                    title="Crop Image"
                    image={this.state.originalImage}
                    // message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                    closeHandler={this.handleCloseCrop}
                    confirmationHandler={this.handleFinishCrop}
                    // declineHandler={this.handleCloseDeclineModal}
                    confirmationLabel="Confirm"
                    declineLabel="Cancel"
                    maxWidth="lg"
                    includeAdd={this.state.includeAdd}
                /> */}


            </div>
        );
    }
}

SocialPostsDetail.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(SocialPostsDetail);
