
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loading from "../../components/loadingSpinner/Loading";
import "./SocialPostsDetail.css";
import Grid from "@material-ui/core/Grid";

import HubTimepicker3 from "../../components/timepicker/HubTimepicker3.js"
//"../../timepicker/HubTimepicker";
import { DatePicker } from "material-ui-pickers";
import EventIcon from "@material-ui/icons/Event";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Typography, TextField, InputAdornment, InputLabel, Button, Select, MenuItem, Tooltip, Menu} from "@material-ui/core";
import Dropzone from "react-dropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import SelectGalleryModal from "../../components/modal/SelectGalleryModal.js"
import ConfirmationModal from "../../components/modal/ConfirmationModal"


import moment from "moment"
import CropImageModal from "../../components/modal/CropImageModal.js"

import { BookingStatusType, ProfileType } from "../../types/enum.js";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import NumberFormatterHelper from "../../helper/numberFormatter";
import EmojiPicker from 'emoji-picker-react';

const FILE_MAXSIZE = 2097152;
const ACCEPTED_FILE = "image/*";
import facebookOn from "../../img/facebook-on.png";
import facebookOff from "../../img/facebook-off.png";
import instagramOn from "../../img/instagram-on.png";
import instagramOff from "../../img/instagram-off.png";
import uploadGallery from "../../img/upload-gallery-image.png";
import uploadImage from "../../img/upload-image.png";
import instagramImage from "../../img/instagram-header-background.png";
import hotgiggityLive from "../../img/hotgiggity-live-marker.png";


import defaultPreviewImage from "../../img/default-preview-image.png";
import facebookFooterPreview from "../../img/facebook-footer-preview.png";
import instagramFooterPreview from "../../img/instagram-footer-preview.png";

const utcOffset = new Date().getTimezoneOffset();

const styles = theme => ({
    root: {
        width: "90%"
    },
    backButton: {
        marginRight: theme.spacing.unit
    },
    instructions: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit
    }
});

@inject("templateStore","bookingRequestStore", "profileStore", "socialPromotionStore", "facebookStore")
@withRouter
@observer
class SocialPostsDetailComponent extends Component {
    state = {
        file: null,
        switchUploadImage: false,
        switchSelectGallery: false,
        openGallery: false,
        image: null,
        feedPreview: 1,
        bookingData: undefined,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1 / 1,
        croppedImage: null,
        croppedAreaPixels: null,
        originalImage: null,
        openCropImage: false,
        something: "",
        includeAdd: false,
        facebookUserName: "Hotgiggity Live",
        instagramUserName: "HotgiggityLive",
        disableFacebook: false,
        disableInstagram: false,
        postOpen: false,
        isSchedule: 1,
        actionAnchor: [],
        firstLoad: true,
        isToday: false,
        openEmoji: false,
        clickPosition: -1,
        isCancel: 0,
        isCancelToggle: false,
        showWarning: false,
        disablePost: false
    };
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.myTextRef = React.createRef();
        
        this.props.socialPromotionStore.reset();
    }

    componentWillUnmount() {
    }

    feedChange = e => {
        this.setState(
            {
                feedPreview: e.target.value
            }
        );
    };
    
    profileChange = e => {
        const { currentProfile } = this.props.profileStore;
        const {bookingData} = this.state
        const {socialPost} = this.props.socialPromotionStore

        var booking = bookingData

        this.props.socialPromotionStore.setImageSocialPost('useProfileSetting', e.target.value);


        var relatedProfile = booking?.act
        var facebookUserName = ""
        if(currentProfile?.type === ProfileType.Musician.ordinal){
            facebookUserName = socialPost.useProfileSetting ? currentProfile?.facebookPageName : !socialPost.showProfileSetting ? currentProfile?.facebookPageName :  relatedProfile?.facebookPageName
        }
        var disableFacebook = false
        if(facebookUserName){
            this.props.socialPromotionStore.setImageSocialPost('switchFacebook', true);
            disableFacebook = false
        }else{
            this.props.socialPromotionStore.setImageSocialPost('switchFacebook', false);
            facebookUserName = "Hotgiggity Live"
            disableFacebook = true
        }
       
        var instagramUserName = ""
        if(currentProfile?.type === ProfileType.Musician.ordinal ){
            instagramUserName = socialPost.useProfileSetting ? currentProfile?.instagramProfile :  !socialPost.showProfileSetting ? currentProfile?.instagramProfile :  relatedProfile?.instagramProfile
        }
        var disableInstagram = false
        if(instagramUserName){
            this.props.socialPromotionStore.setImageSocialPost('switchInstagram', true);
            disableInstagram = false
        }else{
            this.props.socialPromotionStore.setImageSocialPost('switchInstagram', false);
            instagramUserName = "hotgiggitylive"
            disableInstagram = true
            
        }

        this.setState({ facebookUserName: facebookUserName, instagramUserName: instagramUserName, disableFacebook: disableFacebook,  disableInstagram: disableInstagram });
    };
    loadGigs = () => {
        const { socialPost } = this.props.socialPromotionStore;
        const { currentProfile } = this.props.profileStore;
        const { match } = this.props;

        this.props.bookingRequestStore.showLoadingState();
        

        this.props.bookingRequestStore.load(this.props.bookingId,undefined).then(async (booking) => {
            var isDisabled = false
        
            if(booking.status === BookingStatusType.Declined.ordinal || booking.status === BookingStatusType.CancelledByAct.ordinal || booking.status === BookingStatusType.CancelledByActHistory.ordinal || booking.status === BookingStatusType.Cancelled.ordinal){
                isDisabled = true
            }
            booking.startDate = moment(booking.dateStart).subtract(parseFloat(booking.utcOffset) + this.offset,'minute');
            booking.endDate = moment(booking.dateStart).subtract(parseFloat(booking.utcOffset) + this.offset,'minute');
            
            var relatedProfile = currentProfile
            var actProfile = booking?.act
            this.props.socialPromotionStore.setProfileSetting(false);
            if(currentProfile?.type === ProfileType.Musician.ordinal){
                if(booking?.act?.actAdminId === currentProfile?.id){
                    // relatedProfile = booking?.act
                    this.props.socialPromotionStore.setProfileSetting(true);
                    this.props.facebookStore.setRefreshPage(booking?.act?.id).then((result) => {
                        // this.props.profileStore.loadProfiles();
                    })
                }
            }
        
            else if(currentProfile?.type === ProfileType.Agent.ordinal){
               
                if(currentProfile.myAgencies && currentProfile.myAgencies.length){
                    relatedProfile = currentProfile.myAgencies[0].agent
                }
            }else if(currentProfile?.type === ProfileType.Staff.ordinal){
                relatedProfile = booking.venue
            }
            
            var profileId = relatedProfile.id
            var imageResult = null
            await Promise.all([
                this.props.socialPromotionStore.load(this.props.bookingId,profileId),
                this.props.profileStore.getAllImageById(booking.actId)
            ]).then((res) => {
                var socialPostData = res[0]
                imageResult = res[1]
                
                if(this.props.socialPromotionStore.socialPost && this.props.socialPromotionStore.socialPost?.id > 0){
                    var facebookUserName = ""
                    if(currentProfile?.type === ProfileType.Musician.ordinal){
                        facebookUserName = socialPost.useProfileSetting ? currentProfile?.facebookPageName : !socialPost.facebookPageName ? currentProfile?.facebookPageName : actProfile?.facebookPageName
                    }else if(currentProfile?.type === ProfileType.Agent.ordinal ){
                        facebookUserName = relatedProfile?.facebookPageName 
                    }else if(currentProfile?.type === ProfileType.Staff.ordinal){
                        facebookUserName = booking?.venue?.facebookPageName
                    }
                    var disableFacebook = false
                    if(facebookUserName){
                        disableFacebook = false
                    }else{
                        this.props.socialPromotionStore.setImageSocialPost('switchFacebook', false);
                        facebookUserName = "Hotgiggity Live"
                        disableFacebook = true
                    }
                
                    var instagramUserName = ""
                    if(currentProfile?.type === ProfileType.Musician.ordinal){
                        instagramUserName = socialPost.useProfileSetting ? currentProfile?.instagramProfile : !socialPost.showProfileSetting ? currentProfile?.instagramProfile :  actProfile?.instagramProfile
                    }else if(currentProfile?.type === ProfileType.Agent.ordinal ){
                        instagramUserName = relatedProfile?.instagramProfile 
                    }else if(currentProfile?.type === ProfileType.Staff.ordinal){
                        instagramUserName = booking?.venue?.instagramProfile
                    }
                    var disableInstagram = false
                    if(instagramUserName){
                        disableInstagram = false
                    }else{
                        this.props.socialPromotionStore.setImageSocialPost('switchInstagram', false);
                        instagramUserName = "hotgiggitylive"
                        disableInstagram = true
                        
                    }
                }else{
                    let formattedVenueName = booking.venueName ? "#" + booking.venueName?.replace(/ /g, "") : booking.venue ? "#" + booking.venue?.profileName?.replace(/ /g, "") : "";
                    let formattedActName2 = booking.actName ? booking.actName : booking.act ? booking.act?.actName : ""
                    let formattedActName = formattedActName2 ? "#" + formattedActName2.replace(/ /g, "") : "";
                    
                    let bookTableUrl = booking.venue && booking.venue.bookTableUrl ? booking.venue.bookTableUrl  : ""
                    var dateMoment = moment(new Date(booking.dateStart))
        
                    let message = `Hi Folks. I have a gig coming up at ${formattedVenueName} on ${dateMoment.format('dddd')} the ${NumberFormatterHelper.ordinal_suffix_of(parseInt(dateMoment.format("D")))} of ${dateMoment.format("MMMM")} from ${moment(
                        booking.timeStart,
                        "HH:mm:ss" 
                    ).format("h:mm A")} to ${moment(
                        booking.timeEnd,
                        "HH:mm:ss" 
                    ).format("h:mm A")}.\n\nWould love to see you there! \n\n${formattedVenueName} ${formattedActName} #livegigs #supportlocal #livemusic #hotgiggity`

                    if(currentProfile?.type === ProfileType.Agent.ordinal){
                        message = `Hi Folks. Catch ${formattedActName2} performing at ${formattedVenueName} on ${dateMoment.format('dddd')} the ${NumberFormatterHelper.ordinal_suffix_of(parseInt(dateMoment.format("D")))} of ${dateMoment.format("MMMM")} from ${moment(
                            booking.timeStart,
                            "HH:mm:ss" 
                        ).format("h:mm A")} to ${moment(
                            booking.timeEnd,
                            "HH:mm:ss" 
                        ).format("h:mm A")}. \n\nBook a table: ${bookTableUrl} \n\n${formattedVenueName} ${formattedActName} #livegigs #supportlocal #livemusic #hotgiggity`
                    }
                    if(currentProfile?.type === ProfileType.Staff.ordinal){
                        message = `Hi Folks. Catch ${formattedActName2} performing here on ${dateMoment.format('dddd')} the ${NumberFormatterHelper.ordinal_suffix_of(parseInt(dateMoment.format("D")))} of ${dateMoment.format("MMMM")} from ${moment(
                            booking.timeStart,
                            "HH:mm:ss" 
                        ).format("h:mm A")} to ${moment(
                            booking.timeEnd,
                            "HH:mm:ss" 
                        ).format("h:mm A")}. \n\nGet in early and book a table: ${bookTableUrl} \n\n${formattedVenueName} ${formattedActName} #livegigs #supportlocal #livemusic #hotgiggity`
                    }
            
                    this.props.socialPromotionStore.setImageSocialPost('postContent', message);

                var facebookUserName = ""
                var facebookToken = ""
                if(currentProfile?.type === ProfileType.Musician.ordinal){
                    facebookUserName = socialPost.useProfileSetting ? currentProfile?.facebookPageName : actProfile?.facebookPageName ? actProfile?.facebookPageName :currentProfile?.facebookPageName
                    facebookToken = socialPost.useProfileSetting ? currentProfile?.facebookAccessToken : actProfile?.facebookAccessToken ? actProfile?.facebookAccessToken :currentProfile?.facebookAccessToken
                }else if(currentProfile?.type === ProfileType.Agent.ordinal ){
                    facebookUserName = relatedProfile?.facebookPageName 
                    facebookToken = relatedProfile?.facebookAccessToken
                }else if(currentProfile?.type === ProfileType.Staff.ordinal){
                    facebookUserName = booking?.venue?.facebookPageName
                    facebookToken = booking?.venue?.facebookAccessToken
                }
                var disableFacebook = false
                if(facebookUserName && facebookToken){
                    this.props.socialPromotionStore.setImageSocialPost('switchFacebook', true);
                    disableFacebook = false
                }else{
                   
                    this.props.socialPromotionStore.setImageSocialPost('switchFacebook', false);
                    facebookUserName = "Hotgiggity Live"
                    disableFacebook = true
                }

               
                var instagramUserName = ""
                var instagramToken = ""
                    if(currentProfile?.type === ProfileType.Musician.ordinal){
                        instagramUserName = socialPost.useProfileSetting ? currentProfile?.instagramProfile : actProfile?.instagramProfile ? actProfile?.instagramProfile : currentProfile?.instagramProfile
                        instagramToken =  socialPost.useProfileSetting ? currentProfile?.instagramAccessToken : actProfile?.instagramAccessToken ? actProfile?.instagramAccessToken : currentProfile?.instagramAccessToken 
                    }else if(currentProfile?.type === ProfileType.Agent.ordinal ){
                        instagramUserName = relatedProfile?.instagramProfile 
                        instagramToken = relatedProfile?.instagramAccessToken
                    }else if(currentProfile?.type === ProfileType.Staff.ordinal){
                        instagramUserName = booking?.venue?.instagramProfile
                        instagramToken = booking?.venue?.instagramAccessToken
                        
                    }
                var disableInstagram = false
                    if(instagramUserName && instagramToken){
                        this.props.socialPromotionStore.setImageSocialPost('switchInstagram', true);
                        disableInstagram = false
                    }else{
                        this.props.socialPromotionStore.setImageSocialPost('switchInstagram', false);
                        instagramUserName = "hotgiggitylive"
                        disableInstagram = true
                        
                    }
                }
               
               
           
                var isSameDay = true
                if(socialPost){
                    isSameDay = moment(socialPost.scheduleTime).isSame(moment(), 'day');
                }
                this.props.facebookStore.setRefreshPage(profileId).then((result) => {
                    if(result){
                        this.props.profileStore.loadProfiles();
                        this.setState({ image: imageResult, bookingData:booking, facebookUserName: facebookUserName, instagramUserName: instagramUserName, disableFacebook: disableFacebook,  disableInstagram: disableInstagram,isToday: isSameDay,showWarning: false, disablePost: isDisabled });
                    }else{
                        this.setState({ image: imageResult, bookingData:booking, facebookUserName: facebookUserName, instagramUserName: instagramUserName, disableFacebook: disableFacebook,  disableInstagram: disableInstagram,isToday: isSameDay, showWarning: true, disablePost: isDisabled });
                    }
                    
                })
               
            })
           
            window.scrollTo(0, 0);
        })
    };

    // loadSocialContent = () => {
    //     const { currentProfile } = this.props.profileStore;
    //     const { match } = this.props;
    //     var profileId = currentProfile?.id
    //     if(currentProfile?.type === ProfileType.Agent.ordinal){
    //         if(currentProfile.myAgencies && currentProfile.myAgencies.length){
    //             profileId = currentProfile.myAgencies[0].agentId
    //         }
    //     }else if(currentProfile?.type === ProfileType.Staff.ordinal){
    //         profileId = this.state.bookingData?.venueId
    //     }
    //     this.props.socialPromotionStore.load(this.props.bookingId,profileId).then((result) => {
    //        console.log(result)
    //     })
    // };

    componentDidMount = () => {
        const { currentProfile } = this.props.profileStore;
        this.props.bookingRequestStore.showLoadingState();
        this.loadGigs()
    };

    switchFacebookButton = () => {
        let { socialPost } = this.props.socialPromotionStore;
        let {
            switchFacebook
        } = socialPost;
        if(!this.state.disableFacebook){
            this.props.socialPromotionStore.setImageSocialPost('switchFacebook', !switchFacebook);
        }
        
    }
    switchInstagramButton = () => {
        let { socialPost } = this.props.socialPromotionStore;
        let {
            switchInstagram
        } = socialPost;
        if(!this.state.disableInstagram){
            this.props.socialPromotionStore.setImageSocialPost('switchInstagram', !switchInstagram);
        }
    }
    handleChange =(e) => {
        // this.setState({ file: URL.createObjectURL(e.target.files[0]) });
        let image = e.target.files[0];
        if(image.size > FILE_MAXSIZE){
            this.props.templateStore.openSnackbar(
                'image is too big'
            );
            return
         }

        if(image){
            image.url = URL.createObjectURL(e.target.files[0]);
            this.setState({...this.state, originalImage: image.url, openCropImage: true, includeAdd: false});
        }
        
        // setFile(URL.createObjectURL());
    }
    handleEventDateChange = e => {
        this.props.socialPromotionStore.setEventDate(moment(e).startOf("day"))
        var isSameDay = moment(e).isSame(moment(), 'day');
        this.setState({firstLoad: false, isToday: isSameDay})
    }
    handleTimeStartChange = e => {
        var timeString = e.format("HH:mm")
        var timeSplitted = timeString.split(":")
        if(timeSplitted[1] != "00" && timeSplitted[1] != "30"){
            
        }else{
            this.props.socialPromotionStore.setTimeStartString(e);
        }
    };
    

    handleChangeGigImage = (files, rejectedFiles) => {


        let image = files[0];

        if(image){
            image.url = image.preview;
            this.props.socialPromotionStore.setImageSocialPost('gigImage', image);
        }
    }
    handleDeleteGigImage = (imageId) => {
        this.props.socialPromotionStore.setImageSocialPost('gigImage', undefined);
        this.props.socialPromotionStore.unSetFromGalleryImage();

        
    }

    handleDeleteGigImageGallery = () => {
        this.props.socialPromotionStore.unSetFromGalleryImage();
    }
    

    setActionAnchor = (element, bookingId) => {
        var tempAnchor = [];
        tempAnchor[bookingId] = element;
        this.setState({ ...this.state, actionAnchor: tempAnchor });
    };
    unsetActionAchnor = bookingId => {
        this.setState({ ...this.state, actionAnchor: [] });
    };
    
    openCancelToggle = () => {
        this.setState({ ...this.state, isCancelToggle: true});
    };
    closeCancelToggle = () => {
        this.setState({ ...this.state, isCancelToggle: false});
    };
    handleFromChange = date => {
        this.props.socialPromotionStore.setImageSocialPost('scheduleTime', date);
    };
    handleClick2 = event => {
        this.myRef.current.click();
    };
    handleClick = event => {
        this.setState({ switchUploadImage: true,switchSelectGallery: false});
        
    };

    handleClickGallery = event => {
        this.setState({ switchUploadImage: false,openGallery: true});
        
    };

    handleCloseGallery = event => {
        this.setState({ switchUploadImage: false,openGallery: false});
        
    };

    handleCloseCrop = event => {
        this.setState({ openCropImage: false});
        
    };

    handleOpenEmoji = () => {
        this.setState({ openEmoji: !this.state.openEmoji});
        
    };

    closeEmoji = () => {
        this.setState({ openEmoji: false});
        
    };
    
    handleFinishCrop = image => {

        var imageResult = new File([image.file], "croppedimage.jpg");
        imageResult.url =  image.url
        
        this.props.socialPromotionStore.setImageSocialPost('gigImage', imageResult);
        this.setState({ openCropImage: false});
        
    };

    cancelPostSocial = () => {
        let { socialPost } = this.props.socialPromotionStore;

        var id = socialPost?.id
        if(!id){
            return
        }
        this.props.bookingRequestStore.showLoadingState();
        
        this.props.socialPromotionStore.cancelSosicalPost(id).then(response => {
            this.props.bookingRequestStore.hideLoadingState();
            this.props.history.push("/socialposts");
        })
        
    }
    schedulePost = (isPostNow) => {
        const { currentProfile } = this.props.profileStore;
        const { match } = this.props;
        var profileId = currentProfile?.id

        var booking = this.state.bookingData
        if(currentProfile?.type === ProfileType.Musician.ordinal){
            if(booking?.act?.actAdminId === currentProfile?.id){
                // profileId = booking?.act.id
            }
        }
        else if(currentProfile?.type === ProfileType.Agent.ordinal){
            if(currentProfile.myAgencies && currentProfile.myAgencies.length){
                profileId = currentProfile.myAgencies[0].agentId
            }
        }else if(currentProfile?.type === ProfileType.Staff.ordinal){
            profileId = this.state.bookingData?.venueId
        }
        var bookingId = this.props.bookingId
        this.props.bookingRequestStore.showLoadingState();
        
        this.props.socialPromotionStore.schedulePost(bookingId,profileId, isPostNow).then(response => {
            if(response){
                if(this.props.socialPromotionStore.socialPost.gigImage){
                    this.props.socialPromotionStore.setSocialPostImage(response.id, profileId).then(async result => {
                        if(isPostNow){
                            if(this.props.socialPromotionStore.socialPost.switchInstagram && this.props.socialPromotionStore.socialPost.switchFacebook){
                                await this.props.socialPromotionStore.postToInstagram(response.id)
                                await this.props.socialPromotionStore.postToFacebook(response.id)
                                this.props.bookingRequestStore.hideLoadingState();
                                this.props.history.push("/socialposts");
                            }
                            else if(this.props.socialPromotionStore.socialPost.switchInstagram){
                                this.props.socialPromotionStore.postToInstagram(response.id).then(() => {
                                    this.props.bookingRequestStore.hideLoadingState();
                                    this.props.history.push("/socialposts");
                                })
                            }
                            else if(this.props.socialPromotionStore.socialPost.switchFacebook){
                                this.props.socialPromotionStore.postToFacebook(response.id).then(() => {
                                    this.props.bookingRequestStore.hideLoadingState();
                                    this.props.history.push("/socialposts");
                                })
                            }
                          
                        }else{
                            this.props.bookingRequestStore.hideLoadingState();
                            this.props.history.push("/socialposts");
                        }
                    })
                }
            }
        }).catch(error => {
            this.props.bookingRequestStore.hideLoadingState();
            this.props.templateStore.openSnackbar(
                error
            );
        })
    }

    cancelPost = () =>{
        this.props.history.push("/socialposts");
    }

    handleSelectGalleryImage = async (image, isProfile) => {
        // this.props.socialPromotionStore.setFromGalleryImage(image,isProfile)
        // this.setState({ switchUploadImage: false,openGallery: false});
        // this.props.socialPromotionStore.setImageSocialPost('gigImage', undefined);

        var url = isProfile? image.url400Complete : image.url960Complete
        

        this.setState({...this.state, originalImage:url, openCropImage: true, switchUploadImage: false,openGallery: false, includeAdd: true});
    }
    handlePostChange = e => {
        this.setState(
            {
                clickPosition: e.target.selectionEnd
            }
        );
        this.props.socialPromotionStore.setImageSocialPost('postContent', e.target.value);
    }

    clickEvent = e => {
        if(e.target.selectionEnd){
            this.setState(
                {
                    clickPosition: e.target.selectionEnd
                }
            );
        }
       
    }

    handleReaction = e => {
        let { socialPost} = this.props.socialPromotionStore;
        let sym = e.unified.split("-");
        let codesArray = [];
        sym.forEach((el) => codesArray.push("0x" + el));
        let emoji = String.fromCodePoint(...codesArray);

        var text = socialPost.postContent + " " + emoji

        var position = this.state.clickPosition
        if(position > 0){
            text =  socialPost.postContent.slice(0,position) + emoji + socialPost.postContent.slice(position)

        }
       
        this.props.socialPromotionStore.setImageSocialPost('postContent', text);

        this.closeEmoji()
    }
     
    render() {
        const breadcrumb = [{ name: "Promote"}, { name: "Social Posts",link: `/socialposts`  }];
        let { isLoading } = this.props.bookingRequestStore;
        let { socialPost,timeStartString, eventDate, showProfileSetting } = this.props.socialPromotionStore;
        let { bookingData } = this.state;
        const { currentProfile } = this.props.profileStore;

        var relatedProfile = currentProfile

        if(currentProfile?.type === ProfileType.Musician.ordinal){
            if(bookingData?.act?.actAdminId === currentProfile?.id){
                relatedProfile = bookingData?.act
            }
        }
        else if(currentProfile?.type === ProfileType.Agent.ordinal){
            if(currentProfile.myAgencies && currentProfile.myAgencies.length){
                relatedProfile = currentProfile.myAgencies[0].agent
            }
        }else if(currentProfile?.type === ProfileType.Staff.ordinal){
            relatedProfile = this.state.bookingData?.venue
        }
        let {
            scheduleTime,
            gigImage,
            gigGalleryImage,
            isProfile,
            postContent,
            switchInstagram,
            switchFacebook,
            id,
            useProfileSetting
        } = socialPost;
        var buttonText =  this.state.isCancel === 1 ? "Cancel Post" : this.state.isSchedule === 1 ? id ? "Update Post" : "Schedule" : "Publish Now"
        
        return (
            <div>
                    <Paper style={{ position: "relative" }} className="booking-paper-form">
                        <Loading showed={isLoading} />
                        {
                            this.state.showWarning? 
                            this.props.notFromBooking ? 
                            <div style={{backgroundColor:"#ff0c9c", color:"white",height:40, lineHeight: 40,display: "flex",paddingLeft:20,alignItems: "center"}}>
                                <Typography style={{color:"white"}}>Reconnect Your Social Accounts - It looks like your social account connection has expired. To ensure your posts continue to publish smoothly, please reconnect your accounts.</Typography>
                            </div>
                            :  <div style={{backgroundColor:"#ff0c9c",marginLeft:-25,marginRight:-25, color:"white",height:40, lineHeight: 40,display: "flex",paddingLeft:20,alignItems: "center"}}>
                                    <Typography style={{color:"white"}}>Reconnect Your Social Accounts - It looks like your social account connection has expired. To ensure your posts continue to publish smoothly, please reconnect your accounts.</Typography>
                                </div>
                            :
                            null
                        }
                       
                        <Grid container spacing={24} className="social-post-container">
                            <Grid item xs={12} md={7} className="post-paper">
                                <Grid container spacing={24}>
                                    {/* <Grid md={6} className="inside-paper">
                                        <Typography>Scheduled Date & Time</Typography>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DateTimePicker
                                                value={scheduleTime}
                                                fullWidth
                                                margin="dense"
                                                format="ddd, DD-MM-yyyy hh:mm A"
                                                onChange={this.handleFromChange}
                                                keyboardIcon={<EventIcon />}
                                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                                // label="Scheduled Date & Time"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid > */}               

                                    <Grid item md={5} xs={12} className="inside-paper-0">
                                        { !this.state.firstLoad || !id ? 
                                           <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DatePicker
                                                    id="booking-date"
                                                    className="booking-date-picker"
                                                    keyboard
                                                    value={eventDate}
                                                    fullWidth
                                                    margin="dense"
                                                    onChange={this.handleEventDateChange}
                                                    minDate={moment().startOf("days")}
                                                    maxDate={moment(bookingData?.startDate).endOf("days")}
                                                    label="Schedule Date"
                                                    format="DD-MM-YYYY"
                                                    keyboardIcon={<EventIcon />}
                                                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                    rightArrowIcon={<KeyboardArrowRightIcon />}
                                                    style={{ marginTop: 0 }}
                                                    shouldDisableDate={this.shouldDisabledDate}
                                                    errorText="Choose a date & time in the future" 

                                                />
                                            </MuiPickersUtilsProvider>
                                       : 
                                       <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker
                                                id="booking-date"
                                                className="booking-date-picker"
                                                keyboard
                                                value={eventDate}
                                                fullWidth
                                                margin="dense"
                                                onChange={this.handleEventDateChange}
                                                maxDate={moment(bookingData?.startDate).endOf("days")}
                                                label="Schedule Date"
                                                format="DD-MM-YYYY"
                                                keyboardIcon={<EventIcon />}
                                                leftArrowIcon={<KeyboardArrowLeftIcon />}
                                                rightArrowIcon={<KeyboardArrowRightIcon />}
                                                style={{ marginTop: 0 }}
                                                shouldDisableDate={this.shouldDisabledDate}
                                                errorText="Choose a date & time in the future" 

                                            />
                                        </MuiPickersUtilsProvider>
                                    }
                                     
                                    </Grid>
                                    {
                                        this.state.isToday ? 
                                        <Grid item md={3}  xs={12} className="inside-paper-0">
                                            <div>
                                                <HubTimepicker3
                                                    id="time-start"
                                                    margin="none"
                                                    label="Time"
                                                    value={moment(timeStartString, "HH:mm")}
                                                    onChange={this.handleTimeStartChange}
                                                    min={moment().format("HH")}
                                                    // error={error && error["timeStartString"]}
                                                    // helperText={error && error["timeStartString"] ? error["timeStartString"] : "Venue's Local Time"}
                                                />
                                                {/* <TimePicker className="time-picker-body" name="Time Start" onChange={this.handleTimeStartChange} value={moment(timeStartString).format("HH:mm")} openClockOnFocus={false} clockIcon={null} clearIcon={null}/> */}
                                            </div>
                                        
                                        </Grid> :
                                        <Grid item md={3}  xs={12} className="inside-paper-0">
                                                <div>
                                                    <HubTimepicker3
                                                        id="time-start"
                                                        margin="none"
                                                        label="Time"
                                                        value={moment(timeStartString, "HH:mm")}
                                                        onChange={this.handleTimeStartChange}
                                                        // error={error && error["timeStartString"]}
                                                        // helperText={error && error["timeStartString"] ? error["timeStartString"] : "Venue's Local Time"}
                                                    />
                                                    {/* <TimePicker className="time-picker-body" name="Time Start" onChange={this.handleTimeStartChange} value={moment(timeStartString).format("HH:mm")} openClockOnFocus={false} clockIcon={null} clearIcon={null}/> */}
                                                </div>
                                            
                                            </Grid>
                                    }
                                  
                                 
                                    {
                                            showProfileSetting ? 
                                            null : 
                                             <Grid md={4} xs={12} className="inside-paper">
                                                        <Typography>Select Social Channels</Typography>
                                                    
                                                    <Tooltip title={this.state.disableInstagram? "You have not yet connected your instagram account. This can be done via Integrations" : "Connected"}>
                                                        <img onClick={this.switchInstagramButton} className="select-social-channels" src={switchInstagram ? instagramOn : instagramOff}></img>
                                                    </Tooltip>
                                                    <Tooltip title={this.state.disableFacebook?  "You have not yet connected your facebook account. This can be done via Integrations" : "Connected" }>
                                                        <img onClick={this.switchFacebookButton}  className="select-social-channels" src={switchFacebook ? facebookOn : facebookOff}></img>
                                                    </Tooltip>
                                                    {
                                                        this.state.disableFacebook && this.state.disableInstagram ? <p className="small-caption">You haven’t configured your social integrations for this act. Select ‘Personal Profile Settings’ or click <a style={{cursor:"pointer"}} onClick={() => this.props.history.push(currentProfile?.type === ProfileType.Musician.ordinal ? "/musician/edit/integration" : currentProfile?.type === ProfileType.Agent.ordinal ? "/agent/edit/integration" : currentProfile?.type === ProfileType.Staff.ordinal ? `/venues/${bookingData.venueId}/edit/social` : null)}>here</a> to set up now</p>
                                                        : null
                                                    }
                                                    
                                                    
                                             </Grid>
                                    }
                                   
                                </Grid>
                                {
                                            showProfileSetting ? 
                                            <Grid container spacing={24} style={{marginTop:30}}>
                                            <Grid md={5} xs={12}className="inside-paper-0">
                                            <Typography>Connection Setting</Typography>
                                                <Select
                                                    label="Connection Setting"
                                                    className="pull-right"
                                                    fullWidth
                                                    value={useProfileSetting}
                                                    onChange={this.profileChange}
                                                    inputProps={{
                                                        color: "primary"
                                                    }}
                                                    // classes={{
                                                    //     root: "text-white",
                                                    //     icon: "text-white"
                                                    // }}
                                                >
                                                    <MenuItem key={1} value={true}>
                                                        Use Personal Profile Settings
                                                    </MenuItem>
                                                    <MenuItem key={2} value={false}>
                                                        {bookingData?.act?.actName ? `Use Act Profile Settings (${bookingData?.act?.actName})` : "Use Act Profile Settings"}
                                                    </MenuItem>
                                                    
                                                </Select>
                                                
                                                {
                                                    this.state.disableFacebook && this.state.disableInstagram ?
                                                    useProfileSetting ? <p className="small-caption">You haven’t configured your social integrations. Click <a style={{cursor:"pointer"}} onClick={() => this.props.history.push("/musician/edit/integration")}>here</a> to set up now</p> :
                                                    <p className="small-caption">You haven’t configured your social integrations for this act. Select ‘Personal Profile Settings’ or click <a style={{cursor:"pointer"}} onClick={() => this.props.history.push(`/myact/edit/${bookingData?.actId}/social`)}>here</a> to set up now</p>
                                                    : null
                                                }
                                                      
                                            </Grid>
                                            <Grid md={5} xs={12} className="inside-paper-0">
                                                <Typography>Select Social Channels</Typography>
                                               
                                                <Tooltip title={this.state.disableInstagram? "You have not yet connected your instagram account. This can be done via Integrations" : "Connected"}>
                                                    <img onClick={this.switchInstagramButton} className="select-social-channels" src={switchInstagram ? instagramOn : instagramOff}></img>
                                                </Tooltip>
                                                <Tooltip title={this.state.disableFacebook?  "You have not yet connected your facebook account. This can be done via Integrations" : "Connected" }>
                                                    <img onClick={this.switchFacebookButton}  className="select-social-channels" src={switchFacebook ? facebookOn : facebookOff}></img>
                                                </Tooltip>
                                            </Grid>
                                        </Grid> : null

                                }
                             
                                <Grid container spacing={24} className="inside-paper">
                                 
                                        <Typography>Post Content</Typography>
                                        <TextField
                                            ref={this.myTextRef}
                                            placeholder="Write something here..."
                                            multiline
                                            rows={8}
                                            fullWidth
                                            onClick={this.clickEvent}
                                            style={{whiteSpace: "pre-wrap",
                                            overflowWrap: "break-word"}}
                                            value={postContent}
                                            onChange={this.handlePostChange}

                                            InputProps={{
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                      <button style={{ background: "transparent",
                                                        outline: "none",marginTop:"150px",
                                                        border: "none", width:"50px",height:"50px"}} onClick={this.handleOpenEmoji}>
                                                            <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="icon"
                                                            fill="none"
                                                            viewBox="0 0 n24 24"
                                                            stroke="currentColor"
                                                            >
                                                            <path
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                stroke-width="2"
                                                                d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                                            />
                                                            </svg>
                                                        </button>
                                                  </InputAdornment>
                                                ),
                                              }}
                                        />
                                      
                                        {
                                            this.state.openEmoji ? <div>
                                            <EmojiPicker previewConfig={{
  showPreview: false
}} emojiStyle="facebook" height="350px" width="350px" onEmojiClick={this.handleReaction} searchDisabled={true} skinTonesDisabled={true} />
                                        </div> : null
                                        }
                                        
                                </Grid>
                                <Grid container spacing={24} className="inside-paper-2">
                                    <Grid md={12}>
                                        <Typography>Photo(1:1 ratio recommended)</Typography>
                                        <Grid container spacing={24} style={{marginTop:10}}>
                                            <Grid md={4}>
                                                <input ref={this.myRef} type="file" onChange={this.handleChange} style={{ display: "none" }} accept={ACCEPTED_FILE}/>
                                                <Typography className="upload-area" onClick={this.handleClick2}><img className="upload-image" src={uploadImage}></img>  Upload from device</Typography>
                                                {/* <Typography className="upload-area" onClick={this.handleClick}><img onClick={this.switchInstagramButton} className="upload-image" src={uploadImage}></img>  Upload from device</Typography> */}
                                            </Grid>
                                            <Grid md={5}>
                                                <Typography className="upload-area" onClick={this.handleClickGallery}><img className="upload-gallery" src={uploadGallery}></img> Select from act gallery</Typography>
                                            </Grid>
                                            <Grid md={3}>
                                                
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={24} className="inside-paper-3">
                                            <Grid md={12} style={{ display: this.state.switchUploadImage ? "inline" : "none" }}>
                                                <InputLabel>Gallery Image</InputLabel>
                                                <div className="gig-image">
                                                    {!gigImage && (
                                                        <div>
                                                            <Dropzone
                                                                accept={ACCEPTED_FILE}
                                                                maxSize={FILE_MAXSIZE}
                                                                onDrop={this.handleChangeGigImage}
                                                                multiple={false}
                                                                className="dropzone"
                                                            >
                                                                <p>Select file.</p>
                                                            </Dropzone>
                                                        </div>
                                                    )}
                                                   
                                                </div>
                                            </Grid>
                                            <Grid md={12} style={{ display: this.state.switchSelectGallery ? "inline" : "none" }}>
                                                <InputLabel>Event Image</InputLabel>
                                                <div className="gig-image">
                                                    {!gigImage && (
                                                        <div>
                                                            <Dropzone
                                                                accept={ACCEPTED_FILE}
                                                                maxSize={FILE_MAXSIZE}
                                                                onDrop={this.handleChangeGigImage}
                                                                multiple={false}
                                                                className="dropzone"
                                                            >
                                                                <p>Select file.</p>
                                                            </Dropzone>
                                                        </div>
                                                    )}
                                                   
                                                </div>
                                            </Grid>
                                            <Grid md={12}>
                                                <div className="gig-image">
                                                    {gigImage ? (
                                                        <div className="profile-pic-container">
                                                            <img src={gigImage.url} style={{height:300, maxWidth:"100%"}} />
                                                            <Button
                                                                variant="fab"
                                                                color="secondary"
                                                                className="del-avatar"
                                                                onClick={ () => this.handleDeleteGigImage(gigImage.id)}
                                                            >
                                                                <DeleteIcon />
                                                            </Button>
                                                        </div>) :
                                                        <div className="profile-pic-container-empty">
                                                            
                                                        </div> 
                                                    }
                                                    {gigGalleryImage ? (
                                                        <div className="profile-pic-container">
                                                            <img src={isProfile? gigGalleryImage.url400 : gigGalleryImage.url960} style={{height:300, maxWidth:"100%"}} />
                                                            <Button
                                                                variant="fab"
                                                                color="secondary"
                                                                className="del-avatar"
                                                                onClick={this.handleDeleteGigImageGallery}
                                                            >
                                                                <DeleteIcon />
                                                            </Button>
                                                        </div>
                                                        )   :
                                                        <div className="profile-pic-container-empty">
                                                            
                                                        </div> 
                                                    }
                                                </div>
                                            </Grid>
                                           
                                        </Grid>
                                       
                                    </Grid>
                                </Grid>

                                <div className="hide-mobile" style={{marginTop: 30,marginLeft:10}}>
                            {socialPost.postInstagramId || socialPost.postFacebookId ?
                                <Grid container spacing={24} className="inside-paper-3" >
                                    <Grid md={12}>
                                        <Button variant="outlined" color="primary" onClick={this.cancelPost} style={{fontSize:12, width:"200px"}}>
                                            Cancel
                                        </Button>
                                        <Tooltip title={switchInstagram || switchFacebook ? id ? "Update Post" : "Schedule post" : "You need to select a social channel to schedule this post."}>
                                            <Button variant="contained" color="primary" disabled={true} className="second-button-social">
                                                Posted
                                            </Button>
                                            
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            : 
                            this.state.disablePost ? null :
                                <Grid container spacing={24} className="inside-paper-3" > 
                                    <Grid md={12}>
                                        <Button variant="outlined" color="primary" onClick={this.cancelPost} style={{fontSize:12, width:"200px"}}>
                                            Cancel
                                        </Button>
                                        <Tooltip title={switchInstagram || switchFacebook ? this.state.isCancel === 1 ? buttonText : id ? buttonText : buttonText : "You need to select a social channel to schedule this post."}>
                                          <span>
                                          <Button variant="contained" color="primary" disabled={!switchFacebook && !switchInstagram} onClick={this.state.isCancel === 1 ? this.openCancelToggle : this.state.isSchedule === 1 ? () => this.schedulePost(false) : () => this.schedulePost(true)} className="second-button-social">
                                            {buttonText}
                                          </Button>
                                          </span>
                                      </Tooltip>
                                      
                                        <Button variant="contained" color="primary" disabled={!switchFacebook && !switchInstagram} onClick={e => this.setActionAnchor(e.currentTarget, 0)} style={{width:"10px",marginLeft:5, minWidth:10, height:33}} className="second-arrow-social">
                                            <KeyboardArrowDown style={{width:"0.5em", height:"0.5em"}} />
                                        </Button>
                                        <Menu
                                        open={this.state.actionAnchor[0] != null}
                                        onClose={() => this.unsetActionAchnor(0)}
                                        anchorEl={this.state.actionAnchor[0]}
                                    >
                                         <MenuItem
                                            button
                                            className="booking-details"
                                            onClick={() => {
                                                this.setState({isCancel : 0,isSchedule : 0, actionAnchor: [] })
                                            }}
                                        >
                                            Publish Now
                                        </MenuItem>
                                        <MenuItem
                                            button
                                            className="booking-details"
                                            onClick={() => {
                                                this.setState({isCancel : 0,isSchedule : 1, actionAnchor: [] })
                                            }}
                                        >
                                            {id ? "Update Post" : "Schedule"}
                                        </MenuItem>
                                        {id ?  <MenuItem
                                            button
                                            className="booking-details"
                                            onClick={() => {
                                                this.setState({isCancel : 1, actionAnchor: [] })
                                            }}
                                        >
                                            Cancel Post
                                        </MenuItem> : null}
                                      
                                       
                                    </Menu>
                                    </Grid>
                                </Grid>
                            }
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5} className="preview-paper">
                                <Grid md={12} className="preview-drop-down">
                                    <Select
                                        label="Feed Previow"
                                        className="pull-right"
                                        fullWidth
                                        value={this.state.feedPreview}
                                        onChange={this.feedChange}
                                        inputProps={{
                                            color: "primary"
                                        }}
                                        // classes={{
                                        //     root: "text-white",
                                        //     icon: "text-white"
                                        // }}
                                    >
                                        <MenuItem key={1} value={1}>
                                            Instagram Feed Preview
                                        </MenuItem>
                                        <MenuItem key={2} value={2}>
                                            Facebook Feed Preview
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                <Grid style={{paddingLeft:10, paddingTop:50}}>
                                {
                                    this.state.feedPreview === 1 ? 
                                    <div>
                                        <Paper style={{marginRight:10,maxWidth:388,minHeight:450,marginTop:30}}>
                                              <img src={instagramImage} style={{width:"100%", marginBottom:10}} />
                                             <Typography style={{marginBottom:10,  paddingLeft:5, paddingRight:5}} className="upload-area"><img className="instagram-title-preview" src={useProfileSetting ? currentProfile?.instagramImageUrl ? currentProfile?.instagramImageUrl: hotgiggityLive : relatedProfile?.instagramImageUrl? relatedProfile?.instagramImageUrl : hotgiggityLive}></img>  {this.state.instagramUserName}</Typography>
                                             <img src={gigImage ? gigImage.url : gigGalleryImage ? (isProfile? gigGalleryImage.url400 : gigGalleryImage.url960) : defaultPreviewImage} style={{width:"100%", aspectRatio:1/1}} />
                                             {/* <Grid container spacing={24} style={{marginTop:5, marginBottom:5}}>
                                                <Grid md={8}>
                                                <img src={loveInstagram} style={{height:30, marginLeft:10,width:30}} />
                                                <img src={commentInstagram} style={{height:30, marginLeft:5,width:30}} />
                                                <img src={shareInstagram} style={{height:30, marginLeft:5,width:30}} />
                                                </Grid>
                                                <Grid md={4} style={{textAlign:"right"}}>
                                                    <img src={saveInstagram} style={{height:30, marginRight:10,width:30}} />
                                                </Grid>

                                             </Grid> */}
                                            <img src={instagramFooterPreview} style={{width:"100%"}} />
                                             <Typography style={{whiteSpace: "pre-wrap",
                                                overflowWrap: "break-word",marginBottom:10,  paddingLeft:5, paddingRight:5}} className="upload-area">{`${this.state.instagramUserName} ${postContent}`}</Typography>
                                        </Paper>
                                    </div> : null
                                }
                                {
                                    this.state.feedPreview === 2 ? 
                                    <div>
                                        <Paper style={{marginRight:10,maxWidth:388,minHeight:450,marginTop:30}}>
                                            <Grid container spacing={24} style={{marginTop:5, marginBottom:10,  paddingLeft:5, paddingRight:5, paddingTop:5}}>
                                                <Grid md={2}>
                                                    <img className="instagram-title-preview" src={useProfileSetting ? currentProfile?.facebookImageUrl? currentProfile?.facebookImageUrl: hotgiggityLive : relatedProfile?.facebookImageUrl? relatedProfile?.facebookImageUrl : hotgiggityLive}></img> 
                                                </Grid>
                                                <Grid md={10}>
                                                    <Typography className="upload-area">{this.state.facebookUserName}</Typography>
                                                    <Typography className="caption-text">Just now</Typography>
                                                </Grid>
                                            </Grid>
                                            <Typography style={{whiteSpace: "pre-wrap",
                                                overflowWrap: "break-word",marginBottom:10,  paddingLeft:5, paddingRight:5}} className="upload-area">{`${postContent}`}</Typography>
                                             <img src={gigImage ? gigImage.url : gigGalleryImage ? (isProfile? gigGalleryImage.url400 : gigGalleryImage.url960) : defaultPreviewImage} style={{width:"100%",aspectRatio:1/1}} />
                                             <img src={facebookFooterPreview} style={{maxWidth:330,marginLeft:29,marginRight:29}} />
                                        </Paper>
                                    </div> : null
                                }
                                </Grid>
                               
                            </Grid>

                            <div className="hide-desktop" style={{marginTop: 30,marginLeft:10}}>
                            {socialPost.postInstagramId || socialPost.postFacebookId ?
                                <Grid container spacing={24} className="inside-paper-3" >
                                    <Grid md={12}>
                                        <Button variant="outlined" color="primary" onClick={this.cancelPost} style={{fontSize:12, width:"200px"}}>
                                            Cancel
                                        </Button>
                                        <Tooltip title={switchInstagram || switchFacebook ? id ? "Update Post" : "Schedule post" : "You need to select a social channel to schedule this post."}>
                                            <Button variant="contained" color="primary" disabled={true} className="second-button-social">
                                                Posted
                                            </Button>
                                            
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            :  this.state.disablePost ? null :
                                <Grid container spacing={24} className="inside-paper-3" >
                                    <Grid md={12}>
                                        <Button variant="outlined" color="primary" onClick={this.cancelPost} style={{fontSize:12, width:"200px"}}>
                                            Cancel
                                        </Button>
                                        <Tooltip title={switchInstagram || switchFacebook ? id ? "Update Post" : "Schedule post" : "You need to select a social channel to schedule this post."}>
                                            <span>
                                            <Button variant="contained" color="primary" disabled={!switchFacebook && !switchInstagram} onClick={this.state.isSchedule === 1 ? () => this.schedulePost(false) : () => this.schedulePost(true)} className="second-button-social">
                                                {this.state.isSchedule === 1 ? "Schedule" : "Publish Now"}
                                            </Button>
                                            </span>
                                        </Tooltip>
                                        <Button variant="contained" color="primary" disabled={!switchFacebook && !switchInstagram} onClick={e => this.setActionAnchor(e.currentTarget, 1)} style={{width:"10px",marginLeft:5, minWidth:10, height:33}} className="second-arrow-social">
                                            <KeyboardArrowDown style={{width:"0.5em", height:"0.5em"}} />
                                        </Button>
                                        <Menu
                                        open={this.state.actionAnchor[1] != null}
                                        onClose={() => this.unsetActionAchnor(1)}
                                        anchorEl={this.state.actionAnchor[1]}
                                    >
                                        <MenuItem
                                            button
                                            className="booking-details"
                                            onClick={() => {
                                                this.setState({isSchedule : 0, actionAnchor: [] })
                                            }}
                                        >
                                            Publish Now
                                        </MenuItem>
                                        <MenuItem
                                            button
                                            className="booking-details"
                                            onClick={() => {
                                                this.setState({isSchedule : 1, actionAnchor: [] })
                                            }}
                                        >
                                            Schedule
                                        </MenuItem>
                                    </Menu>
                                    </Grid>
                                </Grid>
                            }
                            </div>
                                      
                        </Grid>
                    </Paper>
              
                <SelectGalleryModal
                    open={this.state.openGallery}
                    title="Select 1 Image"
                    backgroundImage={this.state.image?.backgroundImage && this.state.image?.backgroundImage?.length ? this.state.image.backgroundImage : []}
                    profileImage= {this.state.image?.profileImage ? [this.state.image.profileImage] : []}
                    // message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                    closeHandler={this.handleCloseGallery}
                    confirmationHandler={this.handleSelectGalleryImage}
                    // declineHandler={this.handleCloseDeclineModal}
                    confirmationLabel="Confirm"
                    declineLabel="Cancel"
                    maxWidth="lg"
                />

                <CropImageModal
                    open={this.state.openCropImage}
                    title="Crop Image"
                    image={this.state.originalImage}
                    // message="By declining this booking you will remove it from your gig list an will not be able to access it anymore."
                    closeHandler={this.handleCloseCrop}
                    confirmationHandler={this.handleFinishCrop}
                    // declineHandler={this.handleCloseDeclineModal}
                    confirmationLabel="Confirm"
                    declineLabel="Cancel"
                    maxWidth="lg"
                    includeAdd={this.state.includeAdd}
                />
                 <ConfirmationModal
                    open={this.state.isCancelToggle}
                    title="Cancel social post confirmation"
                    message="Are you sure you want to cancel this scheduled post?  "
                    closeHandler={this.closeCancelToggle}
                    confirmationHandler={this.cancelPostSocial}
                    declineHandler={this.closeCancelToggle}
                    confirmationLabel="Confirm"
                />


            </div>
        );
    }
}

SocialPostsDetailComponent.propTypes = {
    classes: PropTypes.object
};
export default withStyles(styles)(SocialPostsDetailComponent);
